import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from '../UICard.module.css';

const UICardButton = React.forwardRef(
  (
    {
      analyticsMetadata,
      backgroundColor = 'white',
      boxShadow = null,
      children,
      height = 'shrink-wrap',
      name,
      onClick = () => {},
      stripEdgeColor = 'brandprimary',
      style = null,
      variant = 'default',
      width = 'fill',
      ...otherButtonProps
    },
    ref,
  ) => {
    if (otherButtonProps.hasOwnProperty('className')) {
      throw new Error(
        'You should not override or extend the built in styling of this component. If you need something more bespoke you should implement it as a bespoke component.',
      );
    }

    const onClickWithAnalytics = (...args) => {
      analytics.track(
        'Button clicked',
        {
          label: name,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
      onClick(...args);
    };

    return (
      <button
        className={cn(styles['UICard'], {
          [styles['UICard--border']]: !!style?.border,
          [styles['UICard--borderRadius']]: !!style?.borderRadius,
          [styles['UICard--stripEdge']]: variant === 'strip-edge',
          [styles['UICard--fullHeight']]: height === 'fill',
          [styles['UICard--fullWidth']]: width === 'fill',
          [styles['UICard--padding']]: !!style?.padding,
          [styles['UICard--shrinkWrapHeight']]: height === 'shrink-wrap',
          [styles['UICard--shrinkWrapWidth']]: width === 'shrink-wrap',
          [styles['UICard--boxShadowHoverFocus']]:
            !!boxShadow && boxShadow === 'focus',
          [styles['UICard--boxShadowHoverResting']]:
            !!boxShadow && boxShadow === 'resting',
          [styles['UICard--boxShadowHoverSubtle']]:
            !!boxShadow && boxShadow === 'subtle',
          [styles['UICard--boxShadowHoverRaised']]:
            !!boxShadow && boxShadow === 'raised',
          [styles['UICard--padding']]: 'padding' in (style || {}),
          [styles['UICard--border']]: !!style?.border,
          [styles['UICard--borderRadius']]: !!style?.borderRadius,
          [styles['UICard--cursor']]: true,
        })}
        onClick={onClickWithAnalytics}
        ref={ref}
        style={{
          '--UICard--border': style?.border ? style.border : 'none',
          '--UICard--borderRadius': style?.borderRadius
            ? style.borderRadius
            : 'none',
          '--UICard--boxShadow': `var(--shadow-${boxShadow})`,
          '--UICard--padding':
            'padding' in (style || {}) ? style.padding : 'none',
          '--UICard--stripEdgeColor': `var(--color-${stripEdgeColor})`,
        }}
        {...otherButtonProps}
      >
        {children}
      </button>
    );
  },
);

UICardButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /** Which of our colors we would like to use as a background
   * "backgroundColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  backgroundColor: PropTypes.string,

  /** Which of our shadows we would like to use
   * "boxShadow" should be a `--shadow-{substring}` substring of the shadow name in theme/shadow.css
   */
  boxShadow: PropTypes.string,

  /** Content to show in the UICardButton */
  children: PropTypes.node.isRequired,

  /** Do we want this UICardButton to fill the height of its container or shrink wrap to its contents? */
  height: PropTypes.oneOf(['fill', 'shrink-wrap']),
  /**
   * Label to be sent with analytics event
   */
  name: PropTypes.string.isRequired,

  /**
   * Callback when this button activates.
   */
  onClick: PropTypes.func,

  /**
   * With the exception of `onClick`, any attribute native to the <button> tag
   * can be passed as a prop and directly spread as an attribute on the component.
   *
   * For instance, if you need to pass a datatest-id, do so like this:
   * ```
   * <Button onClick={clickHandler} data-testid="my-button">Click Me</Button>
   * ```
   */
  otherButtonProps: PropTypes.any,

  /** Only applicable if variant is "strip-edge"
   * "stripEdgeColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  stripEdgeColor: PropTypes.string,

  /** Optionally override some styling */
  style: PropTypes.shape({
    /** Any valid border CSS value will work */
    border: PropTypes.string,
    /** Any valid padding shorthand CSS value will work */
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  /** Which style card do we want to render? */
  variant: PropTypes.oneOf(['default', 'strip-edge']),

  /** Do we want this UICardButton to fill the width of its container or shrink wrap to its contents? */
  width: PropTypes.oneOf(['fill', 'shrink-wrap']),
};

export default UICardButton;
