import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import Analytics from 'site-react/helpers/Analytics';

import styles from './PageNavigationItem.module.css';

const PageNavigationItem = ({ href, isActive, isOutbound, title }) => {
  if (isOutbound) {
    return (
      <li>
        <OutboundLink
          className={styles.PageNavigationItem}
          data-testid="PageNavigationItem-link"
          href={href}
          target="_blank"
        >
          {title}
        </OutboundLink>
      </li>
    );
  } else {
    return (
      <li>
        <NextLink
          className={cn(styles.PageNavigationItem, {
            [styles['PageNavigationItem--active']]: isActive,
          })}
          data-testid="PageNavigationItem-link"
          href={href}
          onClick={() =>
            Analytics.track('Page Navigation Clicked', {
              name: title,
            })
          }
        >
          {title}
        </NextLink>
      </li>
    );
  }
};

PageNavigationItem.propTypes = {
  /**
   * `href` attribute of the item.
   */
  href: PropTypes.string.isRequired,

  /**
   * Is this the current active item?
   */
  isActive: PropTypes.bool.isRequired,

  /**
   * Is this an outbound link?
   */
  isOutbound: PropTypes.bool,

  /**
   * Text of the item.
   */
  title: PropTypes.string.isRequired,
};

export default PageNavigationItem;
