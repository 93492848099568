import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import theme from 'site-react/theme';

const heightOfListingNavigationSmall = 130;
export const heightOfListingNavigationLarge = 60;

const floatingNavigationAnchorStyles = css`
  position: absolute;
  top: -${heightOfListingNavigationSmall}px;
  @media (min-width: ${theme.breakpoints.md}px) {
    top: -${heightOfListingNavigationLarge}px;
  }
`;

const FloatingNavigationAnchor = ({ id }) => (
  <span className={floatingNavigationAnchorStyles} id={id} />
);

FloatingNavigationAnchor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FloatingNavigationAnchor;
