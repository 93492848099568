import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './HubbleLogo.module.css';

const HubbleLogoSVG = ({ color, size, title, ...props }) => (
  <svg
    aria-label={title}
    className={cn(styles['HubbleLogo'])}
    role="img"
    style={{ '--HubbleLogo-color': `var(--color-${color})` }}
    viewBox="0 0 285 285"
    xmlns="http://www.w3.org/2000/svg"
    {...(size
      ? {
          height: size,
          width: size,
        }
      : {})}
    {...props}
  >
    <path
      d="M270.84 86.14C248 26.19 177.82-.2 117.77 0 50.93.22 11.9 39.92 2.14 105.6-7 167.21 12.91 230.3 65.69 264.29c58.17 37.46 104.61 20.5 162.83-28.95 41.96-35.63 62.25-96.94 42.32-149.2zM169.13 217a2.462 2.462 0 0 1-2.44 2.44h-12.24c-1.34 0-2.445-1.1-2.45-2.44v-54.1a2.451 2.451 0 0 0-2.44-2.44H98a2.451 2.451 0 0 0-2.44 2.44v54.6c0 1.34-1.1 2.445-2.44 2.45H80.89a2.462 2.462 0 0 1-2.44-2.45V67.14c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45v73.74c0 1.34 1.1 2.445 2.44 2.45h68.68a2.462 2.462 0 0 1 2.44 2.45zm28.64.47c0 1.34-1.1 2.445-2.44 2.45h-12.24a2.462 2.462 0 0 1-2.44-2.45v-84.22a2.462 2.462 0 0 0-2.44-2.44h-68.83a2.462 2.462 0 0 1-2.44-2.44v-12.24a2.451 2.451 0 0 1 2.44-2.44h68.82a2.462 2.462 0 0 0 2.44-2.45v-44.1c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45z"
      fill={color}
    />
    <path
      d="M93.12 219.95H80.89a2.462 2.462 0 0 1-2.44-2.45V67.14c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45v73.74c0 1.34 1.1 2.445 2.44 2.45h68.68a2.462 2.462 0 0 1 2.44 2.45l.01 71.22a2.462 2.462 0 0 1-2.44 2.44h-12.24c-1.34 0-2.445-1.1-2.45-2.44v-54.1a2.452 2.452 0 0 0-2.44-2.44H98a2.452 2.452 0 0 0-2.44 2.44v54.6a2.462 2.462 0 0 1-2.44 2.45zm102.21-.03h-12.24a2.462 2.462 0 0 1-2.44-2.45v-84.22a2.462 2.462 0 0 0-2.44-2.44h-68.83a2.462 2.462 0 0 1-2.44-2.44v-12.24a2.451 2.451 0 0 1 2.44-2.44h68.82a2.462 2.462 0 0 0 2.44-2.45v-44.1c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45l.02 150.33a2.462 2.462 0 0 1-2.44 2.45z"
      fill="#FFF"
    />
  </svg>
);

const HubbleLogo = ({
  color = 'var(--color-brandprimary)',
  isLink = true,
  size = null,
  title = 'HubbleHQ',
  ...props
}) => {
  const svgProps = {
    color,
    size,
    title,
    ...props,
  };
  if (isLink) {
    return (
      <NextLink href="/">
        <HubbleLogoSVG {...svgProps} />
      </NextLink>
    );
  } else {
    return <HubbleLogoSVG {...svgProps} />;
  }
};

HubbleLogo.propTypes = {
  /**
   * The color of the logomark. Defaults to Hubble brand primary color; you
   * could also use `currentColor` to control the color from a parent. This can
   * be useful for hover states on links.
   */
  color: PropTypes.oneOf(['var(--color-brandprimary)', 'currentColor']),
  /**
   * If the logo is a link or not. Defaults to true.
   */
  islink: PropTypes.bool,
  /**
   * Size of the logo. The Hubble logomark is a square; this will be the width
   * and the height.
   *
   * Defaults to filling its container.
   */
  size: PropTypes.number,

  /**
   * The title. Used for accessibility. Treat it like alt text on an image.
   */
  title: PropTypes.string,
};

export default HubbleLogo;
