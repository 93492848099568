import { css } from '@emotion/css';

import { Paragraph } from 'site-react/components/typography';
import theme from 'site-react/theme';

import BookingButton from './BookingButton';

const rowDetailsWrapperStyle = css`
  align-items: center;
  border-top: 1px solid var(--color-neutral-100);
  display: grid;
  grid-gap: var(--space-sm);
  grid-template-areas: 'copy button';
  justify-content: space-between;
  padding: var(--space-md);
`;

const requestToBookTextWrapperStyle = css`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  grid-area: copy;

  @media (min-width: ${theme.breakpoints.sm}px) {
    flex-direction: row;
    gap: var(--space-xl);
  }
`;

const PassBookingContent = ({
  building,
  capacityText,
  isBookingButtonEnabled,
  priceText,
  product,
}) => {
  return (
    <div className={rowDetailsWrapperStyle}>
      <div className={requestToBookTextWrapperStyle}>
        <Paragraph isMarginless type="content2">
          <b>{capacityText}</b>
        </Paragraph>
        <Paragraph type="content3">
          <b>{priceText}</b>
        </Paragraph>
      </div>
      <BookingButton
        building={building}
        buttonType={product.productType}
        isEnabled={isBookingButtonEnabled}
        product={product}
      />
    </div>
  );
};

export default PassBookingContent;
