import PropTypes from 'prop-types';

export default PropTypes.exact({
  archivedAt: PropTypes.string,
  availableFrom: PropTypes.string,
  building: PropTypes.number,
  buildingDetail: PropTypes.exact({
    address: PropTypes.string,
    archivedAt: PropTypes.string,
    buildingType: PropTypes.string,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
    hostId: PropTypes.number,
    howToFindUs: PropTypes.string,
    id: PropTypes.number,
    isAvailablePartTime: PropTypes.bool,
    isHidden: PropTypes.bool,
    isPromoted: PropTypes.bool,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    overview: PropTypes.string,
    parentArea: PropTypes.number,
    postcode: PropTypes.string,
    publishedAt: PropTypes.string,
    slug: PropTypes.string,
    specialOffer: PropTypes.string,
    status: PropTypes.string,
    updatedAt: PropTypes.string,
    vatRegistered: PropTypes.bool,
    viewingsEndTime: PropTypes.string,
    viewingsStartTime: PropTypes.string,
  }),
  capacity: PropTypes.number,
  createdAt: PropTypes.string,
  deletedAt: PropTypes.string,
  desksAvailable: PropTypes.number,
  floor: PropTypes.arrayOf(PropTypes.string),
  formattedPrice: PropTypes.string,
  hoursPerWeek: PropTypes.number,
  id: PropTypes.number,
  isAvailablePartTime: PropTypes.bool,
  keyFeatures: PropTypes.string,
  maxMonthsTerm: PropTypes.number,
  minMonthsTerm: PropTypes.number,
  notIncluded: PropTypes.string,
  partTimePriceOffPeak: PropTypes.number,
  partTimePricePeak: PropTypes.number,
  price: PropTypes.number,
  priceAutoUpdated: PropTypes.bool,
  pricePlanIdentifier: PropTypes.string, //This value is not a field or property in the database but created in the listing server side props
  publishedAt: PropTypes.string,
  reasonUnpublished: PropTypes.string,
  seatingConfig: PropTypes.oneOf(['private-office', 'part-time-sublet']),
  sqft: PropTypes.number,
  status: PropTypes.oneOf(['archived', 'deleted', 'draft', 'published']),
  typeOfPricePlan: PropTypes.string, //This value is not a field or property in the database but created in the listing server side props
  updatedAt: PropTypes.string,
  url: PropTypes.string,
});
