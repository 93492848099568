import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, TextInput } from 'site-react/components/form';
import { Grid } from 'site-react/components/page';
import { ErrorMessage } from 'site-react/components/typography';
import { PersonaContext } from 'site-react/data/core/PersonaContext';
import analytics from 'site-react/helpers/Analytics';
import getDataLayer from 'site-react/helpers/dataLayer';
import useUser from 'site-react/hooks/useUser';

import attemptLogin from '../../helpers/attemptLogin';
import handleLoginError from '../../helpers/handleLoginError';
import postLoginRedirect from '../../helpers/postLoginRedirect';

const LoginForm = ({
  children,
  existingErrors = null,
  forcedEmail = null,
  isAbleToRedirect = false,
  onLogin = () => {},
  returnUrl = null,
}) => {
  const { checkUser } = useUser();
  const [errorMessages, setErrorMessages] = useState(() => existingErrors);
  const { persistPersona } = useContext(PersonaContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address.'),
    password: Yup.string().required('Please enter your password.'),
  });

  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: forcedEmail || '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const { email, password } = data;

    setErrorMessages([]);

    try {
      await attemptLogin({
        email,
        password,
      });

      analytics.track('Logged In', {
        email,
        provider: 'Spacious',
      });

      getDataLayer().push({
        event: 'login',
      });

      await persistPersona();

      if (isAbleToRedirect) {
        onLogin();
        postLoginRedirect(returnUrl);
      } else {
        await checkUser();
        onLogin();
      }
    } catch (error) {
      const errorMessage = handleLoginError(error);

      setErrorMessages([errorMessage]);
    }
  };

  return (
    <form
      method="post"
      name="Login"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid>
        <Grid.Item colSpan="12">{children}</Grid.Item>

        <Grid.Item colSpan="12">
          <TextInput
            data-testid="LoginForm-email"
            disabled={!!forcedEmail}
            errorText={formState.errors?.email?.message}
            isValidationManaged
            labelText="Email"
            name="email"
            placeholder="e.g. name@workemail.com"
            required
            status={formState.errors?.email?.message && 'error'}
            {...register('email')}
          />
        </Grid.Item>

        <Grid.Item colSpan="12">
          <TextInput
            data-testid="LoginForm-password"
            errorText={formState.errors?.password?.message}
            isValidationManaged
            labelText="Password"
            name="password"
            placeholder="Please enter"
            required
            status={formState.errors?.password?.message && 'error'}
            type="password"
            {...register('password')}
          />
        </Grid.Item>

        {errorMessages?.length > 0 ? (
          <Grid.Item colSpan="12">
            {errorMessages.map((error) => (
              <React.Fragment key={error}>
                <ErrorMessage isCentered>{error}</ErrorMessage>
              </React.Fragment>
            ))}
          </Grid.Item>
        ) : null}

        <Grid.Item colSpan="12">
          <Button
            disabled={formState.isSubmitting || !formState.isValid}
            isBlock
            isLoading={formState.isSubmitting}
            name="Log in"
            styleType="primary"
            type="submit"
          >
            Log in
          </Button>
        </Grid.Item>
      </Grid>
    </form>
  );
};

LoginForm.propTypes = {
  /**
   * Extra content displayed above the login form to add context to the login
   * process. Useful if using this component as a part of a specific user journey,
   * such as Pass.
   */
  children: PropTypes.node.isRequired,

  /**
   * Non-validation errors, such as errors from a social auth service.
   */
  existingErrors: PropTypes.arrayOf(PropTypes.string),

  /**
   * Hard-set the value of the email field. Useful for invitation acceptance.
   */
  forcedEmail: PropTypes.string,

  /**
   * Is this login able to redirect upon completion?
   *
   * If a login form is embedded in a page with custom onLogin/onSignup handlers,
   * this should probably be false. Generally, it will only be true on a page
   * that is dedicated to logging in or signing up.
   */
  isAbleToRedirect: PropTypes.bool,

  /**
   * Callback when a login completes
   */
  onLogin: PropTypes.func,

  /**
   * URL link to redirect user after they log in. Only needed if using
   * Login as a full page feature such as on /login. Should not be set if using login as
   * an in-page modal.
   */
  returnUrl: PropTypes.string,
};

export default LoginForm;
