import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { RuledHeading } from 'site-react/components/typography';
import TransitStop from 'site-react/features/TransitStop';
import transformLandmarksToNearbyLocations from 'site-react/helpers/transformLandmarksToNearbyLocations';
import theme from 'site-react/theme';

import MiniMap from './MiniMap';

const locationStyle = css`
  display: grid;
  grid-column-gap: var(--space-xxl);
  grid-row-gap: var(--space-lg);
  grid-template-areas: 'heading' 'mini-map' 'transit-stops';
  @media (min-width: ${theme.breakpoints.md}px) {
    grid-column-gap: var(--space-xxl);
    grid-row-gap: var(--space-lg);
    grid-template-areas: 'heading heading' 'mini-map transit-stops';
    grid-template-columns: auto 1fr;
  }
`;

const listStyle = css`
  display: flex;
  flex-wrap: wrap;
  grid-area: transit-stops;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Location = ({
  buildingId,
  buildingName,
  isPass,
  landmarks,
  lat,
  lng,
  postcode,
}) => {
  const nearbyLocations = transformLandmarksToNearbyLocations(landmarks, 3);
  return (
    <section className={locationStyle}>
      <div
        className={css`
          grid-area: heading;
        `}
      >
        <RuledHeading level="2">Location</RuledHeading>
      </div>
      <div
        className={css`
          grid-area: mini-map;
        `}
      >
        <MiniMap
          areaName={postcode}
          buildingName={buildingName}
          lat={lat}
          lng={lng}
          trackingId={buildingId}
        />
      </div>
      {nearbyLocations.length ? (
        <ol aria-label="Nearby Transit Stops" className={listStyle}>
          {nearbyLocations
            .filter(({ kind }) => kind === 'transit-stop')
            .map(({ isSearchArea, lines, name, slug, walkingMinutes }) => (
              <li aria-label="Transit Stop" key={slug}>
                <TransitStop
                  isPass={isPass}
                  isSearchArea={isSearchArea}
                  lines={lines}
                  name={name}
                  slug={slug}
                  walkingMinutes={walkingMinutes}
                />
              </li>
            ))}
        </ol>
      ) : null}
    </section>
  );
};

Location.propTypes = {
  buildingId: PropTypes.number.isRequired,
  buildingName: PropTypes.string.isRequired,
  isPass: PropTypes.bool,
  landmarks: PropTypes.arrayOf(
    PropTypes.shape({
      distance: PropTypes.number.isRequired,
      landmark: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            img: PropTypes.string.isRequired,
          }),
        ),
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  postcode: PropTypes.string.isRequired,
};

export default Location;
