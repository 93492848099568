import { css } from '@emotion/css';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import theme from 'site-react/theme';

const passBadgeStyle = css`
  ${theme.largeLabel};
  color: var(--color-brandprimary);
`;
const iconStyle = css`
  color: var(--color-brandprimary);
  font-size: ${theme.font.size.xl};
  margin-bottom: calc(var(--space-xs) / 2);
  margin-right: var(--space-xs);
  vertical-align: middle;
`;

const PassBadge = () => {
  return (
    <span className={passBadgeStyle}>
      <MaterialIcon className={iconStyle} iconType="offline_bolt" />
      On-demand
    </span>
  );
};

export default PassBadge;
