import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { UICard } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { BuildingPropTypes } from 'site-react/proptypes';
import theme from 'site-react/theme';

const imageStyle = css`
  border-radius: var(--space-md);
  width: 100%;
`;

const dataListStyle = css`
  font: var(--font-style-content-2);
  margin-bottom: 0;

  & > div {
    align-items: start;
    display: flex;
    justify-content: space-between;

    @media (min-width: ${theme.breakpoints.md}px) {
      border-bottom: 1px solid var(--color-neutral-200);
      padding: var(--space-md) 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding: var(--space-md) 0 0;
    }

    // Only show the first data point on mobile
    & :not(:first-child) {
      display: none;

      @media (min-width: ${theme.breakpoints.md}px) {
        display: flex;
      }
    }
  }
`;

const descriptionStyle = css`
  text-align: right;
`;

const BookingSummary = ({ building, dataPoints }) => {
  const coverPhoto = building.images[0].img;

  return (
    <UICard style={{ padding: `var(--space-lg)` }}>
      <Heading level="3" type="title4">
        {building.name}
      </Heading>
      <VerticalSpacing size="md" />
      <img
        alt="Building"
        className={imageStyle}
        loading="lazy"
        src={coverPhoto}
      />
      <dl className={dataListStyle}>
        {dataPoints.map((entry) => (
          <div data-testid={`${entry.term}-datapoint`} key={entry.term}>
            <dt>
              <b>{entry.term}</b>
            </dt>
            <dd className={descriptionStyle}>{entry.description}</dd>
          </div>
        ))}
      </dl>
    </UICard>
  );
};

BookingSummary.propTypes = {
  building: BuildingPropTypes.isRequired,
  dataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      term: PropTypes.string,
    }),
  ).isRequired,
};

export default BookingSummary;
