import { css } from '@emotion/css';

import theme from 'site-react/theme';

const hideOnMobileStyle = css`
  /**
 * Isolating this part of the styling as it is significantly simpler to manage visually
 * hidden/removed elements using max-width, but this breakpoint should otherwise be unused
 * to avoid adding comlpexity to this CSS.
 */
  @media (max-width: ${theme.breakpoints.sm - 1}px) {
    display: none;
  }
`;

export const tableStyle = css`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const tableHeaderStyle = css`
  @media (min-width: ${theme.breakpoints.sm}px) {
    background-color: var(--color-neutral-50);

    th {
      font-weight: ${theme.font.weight.light};
      padding: var(--space-sm) 0;
    }
  }

  ${hideOnMobileStyle};
`;

export const tableBodyStyle = css`
  tr {
    border-bottom: 1px solid var(--color-neutral-100);

    &:first-child {
      border-top: 1px solid var(--color-neutral-100);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    &:first-child {
      padding: 16px 16px 16px 16px;
    }
    &:last-child {
      width: 100%;
    }
    padding: 16px 16px 16px 0;
    width: fit-content;
  }
`;

export const dailyHeadingStyle = css`
  display: flex;
  gap: 8px;
`;

export const desktopDetailsStyle = css`
  display: none;

  @media (min-width: ${theme.breakpoints.sm}px) {
    display: block;
    font-size: ${theme.font.size.xs};
  }
`;

export const pricePerHourDetailsStyle = css`
  ${hideOnMobileStyle};

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: var(--space-md);
  }
`;

export const pricePerDayDetailsStyle = css`
  ${hideOnMobileStyle};

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: var(--space-md);
  }
`;

export const priceWrapperStyle = css`
  font: var(--font-style-content-3);
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const buttonContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
