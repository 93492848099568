import { css } from '@emotion/css';

import theme from 'site-react/theme';

// Parent toggle style
export const toggleStyle = css`
  font: var(--font-style-content-2);
  align-items: center;
  background: var(--color-white);
  border-radius: var(--space-sm);
  box-shadow: ${theme.shadow.raised};
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-weight: ${theme.font.weight.medium};
  left: 50%;
  padding: var(--space-sm) var(--space-md);
  position: absolute;
  text-align: center;
  top: var(--space-md);
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
`;

// Toggle sub-styles
export const noResultsStyle = css`
  ${toggleStyle};
  background: var(--color-error);
  color: var(--color-white);
  cursor: default;
  display: flex;
`;

export const redoSearchStyle = css`
  ${toggleStyle};
  background: var(--color-brandsecondary);
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
`;

// Checkbox styles
export const checkboxTypeStyle = css`
  cursor: pointer;
  visibility: hidden;
`;

export const checkedCheckboxStyle = css`
  background-color: var(--color-brandsecondary);
  border: solid 1px var(--color-brandsecondary);
  border-radius: var(--space-base);
  cursor: pointer;
  height: var(--space-lg);
  margin-right: var(--space-sm);
  text-align: left;
  width: var(--space-lg);
`;

export const uncheckedCheckboxStyle = css`
  ${checkedCheckboxStyle};
  background-color: var(--color-white);
  border: solid 1px var(--color-neutral-200);
`;

export const checkedIconStyle = css`
  font-size: ${theme.font.size.lg};
  width: var(--space-md);
  height: 11px;
  margin-left: calc(var(--space-base) / 4);
`;

// Label style
export const redoSearchLabelStyle = css`
  align-items: center;
  display: flex;
`;

export const labelSpacingStyle = css`
  margin-right: var(--space-xs);
`;
