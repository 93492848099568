import PropTypes from 'prop-types';
import React from 'react';

import styles from './SuccessMessage.module.css';

const SuccessMessage = ({ children }) => (
  // aria-live ensures that, when this element appears on the page,
  // its contents are read aloud when using a screen reader.
  <p
    aria-live="polite"
    className={styles.SuccessMessage}
    data-testid="SuccessMessage"
  >
    {children}
  </p>
);

SuccessMessage.propTypes = {
  /**
   * The error message to show. Only phrasing content is allowed! This
   * component’s root is a `<p>`, and should not contain layout elements like
   * a `<div>`.
   */
  children: PropTypes.node.isRequired,
};

export default SuccessMessage;
