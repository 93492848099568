import PropTypes from 'prop-types';
import React from 'react';

import styles from './VerticalSpacing.module.css';

export default function VerticalSpacing({ children = null, size }) {
  return (
    <div
      className={styles.VerticalSpacing}
      style={{ '--VerticalSpacing-size': `var(--space-${size})` }}
    >
      {children}
    </div>
  );
}

VerticalSpacing.propTypes = {
  /**
   * Child components to render.
   */
  children: PropTypes.node,

  /**
   * The size of spacing to use. Must be the name of one of our spacing variables.
   */
  size: PropTypes.oneOf(['base', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'])
    .isRequired,
};
