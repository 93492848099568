import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Meta } from 'site-react/components/seo';
import CookieBanner from 'site-react/features/CookieBanner';
import Footer from 'site-react/features/Footer';
import {
  RecommendationListBuildModeBanner,
  FreeTrialBanner,
  GhostLoginBanner,
} from 'site-react/features/PageBanner';
import SiteNavigation from 'site-react/features/SiteNavigation';
import removeHTML from 'site-react/helpers/removeHTML';
import useAdvisor from 'site-react/hooks/useAdvisor';
import useBrowserProcess from 'site-react/hooks/useBrowserProcess';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';
import useUser from 'site-react/hooks/useUser';

import defaultMeta from '../../content/meta/default';

const PageLayout = ({
  children = null,
  isCookieBannerHidden = false,
  isFooterHidden = false,
  isNavigationHidden = false,
  pageBanner = null,
  meta = {
    title: defaultMeta.title,
    titleSuffix: defaultMeta.titleSuffix,
  },
  navbarIsAnimated = false,
}) => {
  const { isBrowserProcess } = useBrowserProcess();
  const { hasSetPreferences } = useCookiePreferences();
  const { logout, parentUser, user } = useUser();
  const { exitBuildMode, recommendationList } = useAdvisor();

  const title = `${removeHTML(meta.title) || defaultMeta.title} ${
    removeHTML(meta.titleSuffix) || defaultMeta.titleSuffix
  }`;

  const pageBannerOptions = useMemo(() => {
    return {
      'free-trial': { Component: FreeTrialBanner, props: {} },
      'ghost-login': {
        Component: GhostLoginBanner,
        props: {
          onLogout: logout,
          user,
        },
      },
      'recommendations-list-build-mode': {
        Component: RecommendationListBuildModeBanner,
        props: {
          onExit: exitBuildMode,
          recommendationList: recommendationList,
        },
      },
    };
  }, [exitBuildMode, logout, recommendationList, user]);

  const PageBanner = useMemo(() => {
    if (recommendationList) {
      return pageBannerOptions['recommendations-list-build-mode'];
    } else if (parentUser) {
      return pageBannerOptions['ghost-login'];
    } else {
      return pageBannerOptions[pageBanner];
    }
  }, [pageBanner, pageBannerOptions, parentUser, recommendationList]);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Meta {...meta} />
      {PageBanner ? <PageBanner.Component {...PageBanner.props} /> : null}
      {isNavigationHidden ? null : (
        <SiteNavigation darkThemeAnimated={navbarIsAnimated} />
      )}
      {!hasSetPreferences && isBrowserProcess && !isCookieBannerHidden && (
        <CookieBanner />
      )}

      {children}

      {isFooterHidden ? null : <Footer />}
    </>
  );
};

PageLayout.propTypes = {
  isCookieBannerHidden: PropTypes.bool,
  isFooterHidden: PropTypes.bool,
  isNavigationHidden: PropTypes.bool,
  meta: PropTypes.shape({
    title: PropTypes.string,
    titleSuffix: PropTypes.string,
  }),
  navbarIsAnimated: PropTypes.bool,
  pageBanner: PropTypes.oneOf(['free-trial']),
};

export default PageLayout;
