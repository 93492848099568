import PropTypes from 'prop-types';
import React from 'react';

import styles from './Fieldset.module.css';

/**
 * A light touch fieldset wrapper through which we can group several controls
 * as well as labels (<label>) within a web form.
 *
 * Useful when grouping together form inputs like checkboxes or radio buttons.
 */
const Fieldset = ({ children, legend, name, ...props }) => (
  <fieldset className={styles['Fieldset']} name={name} {...props}>
    <legend className={styles['Fieldset-legend']}>{legend}</legend>
    {children}
  </fieldset>
);

Fieldset.propTypes = {
  /**
   * Content to render in the fieldset.
   */
  children: PropTypes.node.isRequired,

  /**
   * A caption for the <fieldset>
   */
  legend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,

  /**
   * The name associated with the group.
   */
  name: PropTypes.string.isRequired,
};

export default Fieldset;
