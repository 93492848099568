import { css } from '@emotion/css';

/**
 * These styles are for if you need an icon in
 * a :before or :after and can't have them in
 * an element.
 */

export const materialIconStyles = css`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
`;
