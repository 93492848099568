import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

import { Label } from 'site-react/components/form';
import { Accordion, Modal, UICard } from 'site-react/components/page';
import {
  Heading,
  MaterialIcon,
  RuledHeading,
} from 'site-react/components/typography';
import {
  HorizontalRule,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';

import Filters from './components/Filters';
import Office from './components/Office';
import styles from './Offices.module.css';
import { DEFAULT_DAYS } from '../../data';
import { HQRenderedMarkdownContentPropTypes } from '../../proptypes';

const days = [
  { label: 'Mo', name: 'monday' },
  { label: 'Tu', name: 'tuesday' },
  { label: 'We', name: 'wednesday' },
  { label: 'Th', name: 'thursday' },
  { label: 'Fr', name: 'friday' },
];

function AboveMdFilters({
  enableOccupancy,
  dispatchSearchState,
  searchState,
  submitParams,
}) {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (window.innerWidth >= 768) {
      submitParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  return (
    <Filters
      enableOccupancy={enableOccupancy}
      onChange={dispatchSearchState}
      searchState={searchState}
    />
  );
}

export default function Offices({
  buildingName,
  buildingType,
  dispatchSearchState,
  location,
  matches,
  other,
  partTimeProductSettings,
  pricePlans,
  renderedMarkdownContent,
  searchState,
  specialOffer,
  submitParams,
}) {
  const hasPartTimePricePlans = pricePlans.some((pp) => pp.isAvailablePartTime);

  const buttonContent = useMemo(() => {
    let content = [];

    if (searchState?.peopleMin) {
      content.push([
        'people-min',
        <b key="people-min-content">
          {searchState.peopleMin}{' '}
          {searchState.peopleMin > 1 ? 'people' : 'person'}
        </b>,
      ]);
    } else {
      content.push([
        'people-min',
        <span key="people-min-content">
          <b>Any size</b>
        </span>,
      ]);
    }
    if (
      searchState?.access?.includes('fullTime') ||
      [0, undefined].includes(searchState?.access)
    ) {
      content.push([
        'full-time',
        <span key="full-time">
          <b>Full-time</b>
        </span>,
      ]);
    }

    if (hasPartTimePricePlans) {
      if (
        searchState?.access?.includes('partTime') ||
        [0, undefined].includes(searchState?.access)
      ) {
        let daysValue = searchState.days ?? DEFAULT_DAYS;
        if (typeof daysValue === 'string') {
          daysValue = [daysValue];
        }

        content.push([
          'part-time',
          <span key="part-time-content">
            <b>Part-time</b>
            <div className={styles['Offices--datesSelected']}>
              {[...daysValue]
                .sort((a, b) => {
                  return (
                    days.findIndex((day) => day.name === a) -
                    days.findIndex((day) => day.name === b)
                  );
                })
                .map((day, index, array) => {
                  return (
                    <abbr
                      className={styles['Offices--displayDate']}
                      key={day}
                      title={day}
                    >
                      {days.find((d) => d.name === day).label}
                      {index < array.length - 1 ? ', ' : ''}
                    </abbr>
                  );
                })}
            </div>
          </span>,
        ]);
      }
    }
    return content;
  }, [
    searchState?.access,
    searchState.days,
    searchState.peopleMin,
    hasPartTimePricePlans,
  ]);

  return (
    <>
      <UICard
        backgroundColor="neutral-25"
        boxShadow="resting"
        style={{ padding: 'var(--space-md)' }}
      >
        <div className={styles['u-displayBelowLg']}>
          <Modal
            id="listing-page-search-filters"
            label="Listing Page Search Filters"
            modalName="listing-page-search-filters"
            renderTrigger={({ openModal }) => (
              <Label>
                <Heading type="title4">Filter office results</Heading>
                <VerticalSpacing size="sm" />
                <button
                  className={styles['Offices--filterDisplay']}
                  data-testid="open-search-filters"
                  onClick={openModal}
                >
                  {buttonContent.map(([contentKey, content]) => (
                    <div
                      className={styles['Offices-filterItem']}
                      key={contentKey}
                    >
                      {content}
                    </div>
                  ))}
                  <div
                    className={[
                      styles['Offices-filterItem'],
                      styles['Offices-filterItemIcon'],
                    ].join(' ')}
                  >
                    <MaterialIcon iconType="mode_edit" />{' '}
                  </div>
                </button>
              </Label>
            )}
          >
            {({ closeModal }) => (
              <>
                <div className={styles['Offices-filterHeading']}>
                  <RuledHeading level="2">Office filters</RuledHeading>
                </div>
                <Filters
                  enableOccupancy={hasPartTimePricePlans}
                  onChange={dispatchSearchState}
                  onSubmit={() => {
                    submitParams();
                    closeModal();
                  }}
                  searchState={searchState}
                />
              </>
            )}
          </Modal>
        </div>
        <div className={styles['u-displayAboveLg']}>
          <div className={styles['Offices-filterHeading']}>
            <Heading type="title4"> Filter office results </Heading>
          </div>
          <AboveMdFilters
            dispatchSearchState={dispatchSearchState}
            enableOccupancy={hasPartTimePricePlans}
            searchState={searchState}
            submitParams={submitParams}
          />
        </div>
      </UICard>

      <VerticalSpacing size="xl" />
      <Heading type="title3">Office Matches ({matches.length})</Heading>
      <VerticalSpacing size="md" />
      <HorizontalRule />
      <VerticalSpacing size="xl" />
      <UnstyledList data-testid="matching-priceplans">
        {matches.map((matchingPricePlan) => {
          const renderedMarkdownPricePlanContent =
            renderedMarkdownContent.pricePlans.find(
              (content) => content.id === matchingPricePlan.id,
            );

          return (
            <Office
              buildingName={buildingName}
              buildingType={buildingType}
              isHighlighted={matchingPricePlan.isHighlighted}
              key={matchingPricePlan.pricePlanIdentifier}
              location={location}
              partTimeProductSettings={partTimeProductSettings}
              pricePlan={matchingPricePlan}
              renderedMarkdownPricePlanContent={
                renderedMarkdownPricePlanContent
              }
              specialOffer={specialOffer}
            />
          );
        })}
      </UnstyledList>
      {other.length >= 1 && (
        <Accordion
          answer={
            <UnstyledList data-testid="other-priceplans">
              {other.map((otherPricePlan) => {
                const renderedMarkdownPricePlanContent =
                  renderedMarkdownContent.pricePlans.find(
                    (content) => content.id === otherPricePlan.id,
                  );

                return (
                  <Office
                    buildingName={buildingName}
                    buildingType={buildingType}
                    isHighlighted={otherPricePlan.isHighlighted}
                    key={otherPricePlan.pricePlanIdentifier}
                    location={location}
                    pricePlan={otherPricePlan}
                    renderedMarkdownPricePlanContent={
                      renderedMarkdownPricePlanContent
                    }
                    specialOffer={specialOffer}
                  />
                );
              })}
            </UnstyledList>
          }
          question={
            <Heading type="title3">Show other offices ({other.length})</Heading>
          }
          underlineQuestion={true}
        />
      )}
    </>
  );
}

Offices.propTypes = {
  buildingName: PropTypes.string.isRequired,

  buildingType: PropTypes.shape({
    desc: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
    longDescription: PropTypes.string,
  }),

  dispatchSearchState: PropTypes.func.isRequired,

  location: PropTypes.string.isRequired,

  matches: PropTypes.arrayOf(
    PropTypes.shape({
      availableFrom: PropTypes.string,
      capacity: PropTypes.number,
      desksAvailable: PropTypes.number,
      floor: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.number.isRequired,
      keyFeatures: PropTypes.string,
      maxMonthsTerm: PropTypes.number,
      minMonthsTerm: PropTypes.number,
      price: PropTypes.number.isRequired,
      seatingConfig: PropTypes.oneOf(['part-time-sublet', 'private-office'])
        .isRequired,
      sqft: PropTypes.number,
      status: PropTypes.oneOf(['archived', 'published']).isRequired,
    }),
  ),

  other: PropTypes.arrayOf(
    PropTypes.shape({
      availableFrom: PropTypes.string,
      capacity: PropTypes.number,
      desksAvailable: PropTypes.number,
      floor: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.number.isRequired,
      keyFeatures: PropTypes.string,
      maxMonthsTerm: PropTypes.number,
      minMonthsTerm: PropTypes.number,
      price: PropTypes.number.isRequired,
      seatingConfig: PropTypes.oneOf(['part-time-sublet', 'private-office'])
        .isRequired,
      sqft: PropTypes.number,
      status: PropTypes.oneOf(['archived', 'published']).isRequired,
    }),
  ),

  pricePlans: PropTypes.arrayOf(
    PropTypes.shape({
      availableFrom: PropTypes.string,
      capacity: PropTypes.number,
      desksAvailable: PropTypes.number,
      floor: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.number.isRequired,
      keyFeatures: PropTypes.string,
      maxMonthsTerm: PropTypes.number,
      minMonthsTerm: PropTypes.number,
      price: PropTypes.number.isRequired,
      seatingConfig: PropTypes.oneOf(['part-time-sublet', 'private-office'])
        .isRequired,
      sqft: PropTypes.number,
      status: PropTypes.oneOf(['archived', 'published']).isRequired,
    }),
  ),

  renderedMarkdownContent: HQRenderedMarkdownContentPropTypes.isRequired,

  searchState: PropTypes.shape({
    access: PropTypes.arrayOf(PropTypes.string),
    days: PropTypes.arrayOf(PropTypes.string),
    peopleMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  specialOffer: PropTypes.string,

  submitParams: PropTypes.func.isRequired,
};
