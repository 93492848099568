import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from './CustomDropdown.module.css';
/**
 * A dropdown, with a customized appearance when unopened.
 *
 * When opened, this dropdown will show the browser-default `<select>`, invisibly, on top of the custom `displayComponent` provided.
 *
 * In order to have a focus state, for accessibility and keyboard navigability, we have permitted the breaking of component and element isolation in CSS. On your `displayComponent`'s CSS, you can do something like:
 *
 * ```
 * select:focus + & {
 *   box-shadow: ${theme.shadow.focus};
 * }
 * ```
 *
 * See the default story's definition code for more details.
 */

const CustomDropdown = forwardRef(
  (
    {
      analyticsMetadata = {},
      children,
      disabled = false,
      displayComponent,
      name = null,
      onChange = () => {},
      onClick = () => {},
      testId = null,
      value = null,
      ...otherInputProps
    },
    ref,
  ) => (
    <div className={styles['CustomDropdown-wrapper']}>
      <select
        className={styles['CustomDropdown-select']}
        data-testid={testId}
        disabled={disabled}
        name={name}
        onChange={onChange}
        onClick={(e) => {
          analytics.track(
            'Dropdown opened',
            {
              ...analyticsMetadata,
            },
            {
              sendPageProperties: true,
            },
          );

          onClick(e);
        }}
        ref={ref}
        /* Only set the value prop if we have one. Otherwise, React switches into controlled-component mode. */
        {...(value ? { value } : {})}
        {...otherInputProps}
      >
        {children}
      </select>
      {displayComponent}
    </div>
  ),
);

CustomDropdown.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Options to show when the dropdown is open.
   *
   * Required. If there are no options available, consider passing a disabled option.
   */
  children: PropTypes.node.isRequired,

  /**
   * Is this dropdown active, and useable by the user?
   *
   * _Note:_ not prefixing with `is` here, because the DOM `<select>` API uses `disabled`
   */
  disabled: PropTypes.bool,

  /**
   * The custom component to show when the dropdown is closed.
   */
  displayComponent: PropTypes.node.isRequired,

  /**
   * The name, passed onto the underlying <select> element. Can be useful in some form scenarios.
   */
  name: PropTypes.string,

  /**
   * Callback when the dropdown value changes. Will be called with the event from the underlying <select> element.
   */
  onChange: PropTypes.func,

  /**
   * Callback when the dropdown is clicked (or otherwise opened). Will be called with the event from the underlying <select> element.
   */
  onClick: PropTypes.func,

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,

  /**
   * The currently-selected value within the dropdown.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomDropdown;
