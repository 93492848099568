import Image from 'next/image';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { Curve } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './PassPromotionCard.module.css';

const PassPromotionCard = () => {
  return (
    <div className={styles['PassPromotionCard']}>
      <div className={styles['PassPromotionCard-imageWrapper']}>
        <Image
          alt="find workspaces on hubble illustration"
          fill={true}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/pass/employees-in-a-meeting-room.png',
            {},
          )}
          style={{ objectFit: 'cover' }}
        />
        <div className={styles['PassPromotionCard-swoopWrapper']}>
          <Curve
            bottomColor="white"
            height="md"
            topColor="transparent"
            type="wave"
          />
        </div>
      </div>
      <div className={styles['PassPromotionCard-ctaWrapper']}>
        <Heading isCentered level="3" type="title1">
          Unlock hassle-free workspace access for your team
        </Heading>
        <VerticalSpacing size="md" />
        <LinkAsButton
          href="/pass"
          isCompact
          name="Explore Hubble On-Demand"
          rel="nofollow noopener noreferrer"
          styleAtSmall="auto"
          styleType="secondary"
          target="_blank"
        >
          Explore Hubble On-Demand
        </LinkAsButton>
        <VerticalSpacing size="md" />
      </div>
    </div>
  );
};

export default PassPromotionCard;
