import { css } from '@emotion/css';
import PropTypes from 'prop-types';

import { Grid, Section } from 'site-react/components/page';
import { VerticalSpacing } from 'site-react/components/utility';
import theme from 'site-react/theme';

const formWrapperStyle = css`
  @media (min-width: ${theme.breakpoints.md}px) {
    border: 1px solid var(--color-neutral-50);
    border-radius: var(--space-md);
    box-shadow: ${theme.shadow.resting};
    padding: var(--space-lg);
  }
`;

const noShadowStyle = css`
  padding: var(--space-lg);
`;

const modalWrapperStyle = css`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.lg}px) {
    flex-direction: row-reverse;
  }
`;

const modalContentWrapperStyle = css`
  background-color: var(--color-brandsecondary-background);
  padding: var(--space-xxl);
  display: flex;
  flex-direction: column;
  gap: var(--space-md);

  @media (min-width: ${theme.breakpoints.lg}px) {
    width: 50%;
    height: auto;
  }
`;

const modalFormWrapperStyle = css`
  padding: var(--space-xxl) var(--space-md);

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: var(--space-xxl);
  }

  @media (min-width: ${theme.breakpoints.lg}px) {
    width: 50%;
  }
`;

const showInSmallScreenStyle = css`
  display: block;

  @media (min-width: ${theme.breakpoints.lg}px) {
    display: none;
  }
`;

const showInLargeScreenStyle = css`
  display: none;

  @media (min-width: ${theme.breakpoints.lg}px) {
    display: block;
  }
`;

const contentBreakStyle = css`
  @media (min-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`;

const LayoutWrapper = ({
  layoutStyle,
  isBookingInProgress = false,
  isSignUp,
  signupHeading,
  loginHeading,
  content,
  children,
}) => {
  const modalLayout = (
    <div className={modalWrapperStyle}>
      <div className={modalFormWrapperStyle}>
        <div className={showInSmallScreenStyle}>
          <VerticalSpacing size="sm" />
          {isSignUp ? signupHeading : loginHeading}
        </div>
        <VerticalSpacing size="sm" />
        {children}
      </div>

      <div className={modalContentWrapperStyle}>
        <div className={showInLargeScreenStyle}>
          {isSignUp ? signupHeading : loginHeading}
        </div>
        {content}
      </div>
    </div>
  );

  const pageLayout = (
    <Section verticalPadding="lg">
      <VerticalSpacing size="md" />
      <Grid>
        <Grid.Item colSpan="7">
          <div
            className={isBookingInProgress ? noShadowStyle : formWrapperStyle}
          >
            {children}
          </div>
        </Grid.Item>

        <div className={contentBreakStyle}>
          <VerticalSpacing size="sm" />
        </div>

        <Grid.Item colSpan="5">{content}</Grid.Item>
      </Grid>
    </Section>
  );

  return layoutStyle === 'modal' ? modalLayout : pageLayout;
};

LayoutWrapper.propTypes = {
  children: PropTypes.node,

  /**
   * Extra content to explain the purpose of logging in/signing up. Good to use for
   * added context when used as part of a user journey for a specific experience, such
   * as Pass.
   *
   * Can be used to, for example, explain that by logging in, you'll be joining
   * an organisation.
   */
  content: PropTypes.node,

  isBookingInProgress: PropTypes.bool,

  /**
   * Is this a sign up form?
   */
  isSignUp: PropTypes.bool,

  /**
   * Define the style for this component depending on where it's being implemented.
   *
   * Defaults to `page`
   */
  layoutStyle: PropTypes.oneOf(['page', 'modal']),

  /**
   * The signup heading component
   */
  loginHeading: PropTypes.node,

  /**
   * The signup heading component
   */
  signupHeading: PropTypes.node,
};

export default LayoutWrapper;
