import { css, cx } from '@emotion/css';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import {
  Heading,
  MaterialIcon,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { imgixUrl } from 'site-react/helpers/imgix';
import { ProductPriceFormatter } from 'site-react/helpers/productPriceFormatter';
import usePriceType from 'site-react/hooks/usePriceType';
import { BuildingPropTypes } from 'site-react/proptypes';
import theme from 'site-react/theme';

const venueImageWrapperStyle = css`
  align-items: center;
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-bottom: var(--space-md);

  @media (min-width: ${theme.breakpoints.md}px) {
    margin-bottom: 0;
    margin-right: var(--space-lg);
  }
`;

const venueImageStyle = css`
  background-color: var(--color-neutral-50);
  border-radius: var(--space-sm);
  object-fit: cover;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}px) {
    aspect-ratio: 1;
    width: 115px;
  }
`;

const bookingDescriptionWrapperStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const buildingIconStyle = css`
  color: var(--color-neutral-100);
  font-size: 80px;
  text-align: center;
  width: 115px;
`;

const cardWrapperStyle = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}px) {
    flex-direction: row;
  }
`;

const fadeStyle = css`
  opacity: 0.6;
`;

function BookingSummaryCard({
  booking,
  bookingType,
  building,
  buildingImagePath = null,
  headCount = null,
  costInCredits = null,
  formattedBookingDate = null,
  formattedBookingStartTime = null,
  formattedEndTime = null,
  googleCalendarUrl = null,
  isInThePast = false,
  optionParam = null,
}) {
  const priceType = usePriceType();
  const priceFormatter = new ProductPriceFormatter('en-GB', 'GBP');
  const isMeetingRoomBooking = bookingType === 'meetingroom';
  const isPrivateOfficeBooking = bookingType === 'privateoffice';
  const isCoworkingBooking = bookingType === 'coworking';
  const [costInCurrency, setCostInCurrency] = useState(0);

  useEffect(() => {
    if (isMeetingRoomBooking || isPrivateOfficeBooking) {
      setCostInCurrency(booking?.grossPriceInCurrency);
    }
    if (isCoworkingBooking) {
      setCostInCurrency(
        booking?.attendees?.length * booking?.attendees[0].priceInCurrency,
      );
    }
  }, [
    booking,
    isMeetingRoomBooking,
    isPrivateOfficeBooking,
    isCoworkingBooking,
  ]);

  const formattedPrice = priceFormatter.getFormattedPrice(
    priceType === 'credits' ? costInCredits : costInCurrency,
    priceType,
  );

  return (
    <div className={cx(cardWrapperStyle, isInThePast && fadeStyle)}>
      <div className={venueImageWrapperStyle}>
        {buildingImagePath ? (
          <img
            alt={building.name}
            className={venueImageStyle}
            src={imgixUrl(buildingImagePath, {
              fit: 'crop',
            })}
          />
        ) : (
          <MaterialIcon className={buildingIconStyle} iconType="apartment" />
        )}
      </div>
      <div className={bookingDescriptionWrapperStyle}>
        <NextLink
          href={{
            pathname: `/pass/office-space/${building.id}/${building.slug}`,
            query: {
              ...(optionParam && { option: optionParam }),
            },
          }}
        >
          <Heading level="4" type="title4">
            {building.name}
          </Heading>
        </NextLink>
        <VerticalSpacing size="xs" />
        <Paragraph isMarginless type="content3">
          {bookingType.includes('meeting')
            ? `${headCount}-person meeting room`
            : bookingType === 'coworking'
              ? `Coworking space `
              : bookingType.includes('office')
                ? `Private office `
                : `Workspace `}
          {headCount > 0 && !bookingType.includes('meeting') && (
            <span>
              for {headCount} {headCount > 1 ? 'people' : 'person'}
            </span>
          )}
        </Paragraph>
        <VerticalSpacing size="xs" />
        <Paragraph isMarginless type="content3">
          {formattedBookingDate && (
            <strong suppressHydrationWarning>
              {bookingType.includes('meeting') && formattedBookingStartTime
                ? `${formattedBookingStartTime} - ${formattedEndTime}`
                : formattedBookingDate}
            </strong>
          )}
        </Paragraph>
        <VerticalSpacing size="xs" />
        {formattedPrice && booking && (
          <Paragraph isMarginless type="content2">
            {formattedPrice}
          </Paragraph>
        )}
        {googleCalendarUrl && (
          <OutboundLink href={googleCalendarUrl} target="_blank">
            <TextWithIcon
              contentType="content1"
              iconColor="neutral-900"
              iconName="calendar_today"
              iconPosition="left"
              iconSize="md"
              text={
                <span
                  className={css`
                    font: var(--font-style-content-2);
                  `}
                >
                  Add to Google calendar
                </span>
              }
            />
          </OutboundLink>
        )}
      </div>
    </div>
  );
}

export default BookingSummaryCard;

BookingSummaryCard.propTypes = {
  /** The bookingType can have different spellings depending on where it is used
   * so we check for substrings rather than string equality
   * e.g. 'private-office' or 'privateoffice' are checked for the substring 'office'
   */
  bookingType: PropTypes.string.isRequired,

  /** Used to display the building name and image, and to construct the URL */
  building: BuildingPropTypes.isRequired,

  buildingImagePath: PropTypes.string,

  /** Display the cost for this booking if it is known */
  costInCredits: PropTypes.string,

  /** If no start and end times are available, will just display the date */
  formattedBookingDate: PropTypes.string,
  formattedBookingStartTime: PropTypes.string,
  formattedEndTime: PropTypes.string,

  /** A link through which the organiser can generate and add a Google Calendar event for the booking */
  googleCalendarUrl: PropTypes.string,

  /** Used for both the number of attendees or the room capacity
   * as we never need or get both at the same time
   */
  headCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** If a booking has already taken place. */
  isInThePast: PropTypes.bool,

  /** If provided, adds the product as an option in the Building link
   * e.g. ?option=coworking or ?option=10person-meeting-room-11ph
   */
  optionParam: PropTypes.string,
};
