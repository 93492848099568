import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './Badge.module.css';

const Badge = ({
  color = 'white',
  desc = '',
  iconName = '',
  isBorderVariant = false,
  isFullWidth,
  isInverted = false,
  isSmall = false,
  label,
}) => {
  let textColor;

  if (isInverted) {
    textColor = color;
  } else if (['white', 'brandsecondary', 'green-200'].includes(color)) {
    textColor = 'neutral-900';
  } else if (['brandprimary', 'neutral-900'].includes(color)) {
    textColor = 'white';
  }

  const badgeColor = {
    bgColor: isInverted ? 'white' : color,
    textColor,
  };

  return (
    <div
      className={cn(styles['Badge--wrapper'], {
        [styles['Badge--fullWidth']]: isFullWidth,
        [styles['Badge--marginSmall']]: !isSmall,
        [styles['Badge--marginExtraSmall']]: isSmall,
      })}
      data-testid={`Badge-${label}`}
      style={{
        '--Badge--backgroundColor': `var(--color-${badgeColor.bgColor})`,
        '--Badge--textColor': `var(--color-${badgeColor.textColor})`,
      }}
      title={desc}
    >
      {iconName && (
        <MaterialIcon className={styles['Badge-icon']} iconType={iconName} />
      )}
      <span
        className={
          (isSmall &&
            cn(styles.Badge, styles['Badge--small'], {
              [styles['Badge--fullWidth']]: isFullWidth,
              [styles['Badge--borderedBadge']]: isBorderVariant,
            })) ||
          (isBorderVariant &&
            cn(styles.Badge, styles['Badge--borderedBadge'], {
              [styles['Badge--fullWidth']]: isFullWidth,
            })) ||
          cn(styles.Badge, { [styles['Badge--fullWidth']]: isFullWidth })
        }
        style={{
          '--Badge--borderColor': `var(--color-${isInverted ? color : 'neutral-900'})`,
        }}
      >
        {label}
      </span>
    </div>
  );
};

Badge.propTypes = {
  /**
   * The color of the badge
   */
  color: PropTypes.oneOf([
    'brandprimary',
    'brandsecondary',
    'white',
    'neutral-900',
    'green-200',
    'green-600',
  ]),

  /**
   * The text in the hover/takeover
   */
  desc: PropTypes.string,

  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconName: PropTypes.string,

  /**
   * Bool that applies a 1px border on white background labels on listing pages
   */
  isBorderVariant: PropTypes.bool,

  /**
   * Bool that will expand the badge to fill it's container horiontally
   */
  isFullWidth: PropTypes.bool,

  /**
   * Bool that inverts textColor and borderColor (if any) with background color if true
   */
  isInverted: PropTypes.bool,

  /**
   * Is badge small?
   */
  isSmall: PropTypes.bool,

  /**
   * The text of the badge
   */
  label: PropTypes.string.isRequired,
};

export default Badge;
