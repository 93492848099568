import PropTypes from 'prop-types';

import { Button, TextInput } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import ModalNew from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';
import useUser from 'site-react/hooks/useUser';
import { BuildingPropTypes, PricePlanPropTypes } from 'site-react/proptypes';

import AdvisorPromotionCard from './components/AdvisorPromotionCard';
import OfficeDetail from './components/OfficeDetail';
import FullTimeQuoteModal from '../FullTimeQuoteModal';
import PartTimeQuoteModal from '../PartTimeQuoteModal';

export default function OfficeFinder({
  building,
  dispatchSearchState,
  matches,
  searchState,
  submitParams,
}) {
  const pricePlan = matches ? matches[0] : null;
  const otherOptionsCount = Math.max(matches.length - 1, 0);

  const { enableQuoteModals } = useFeatureFlags();
  const { user } = useUser();

  const QuoteModal =
    pricePlan?.typeOfPricePlan === 'part-time-office'
      ? PartTimeQuoteModal
      : FullTimeQuoteModal;

  return (
    <>
      <Heading type="title4">Interested in this building?</Heading>
      <VerticalSpacing size="md" />

      <TextInput
        aria-label="Team size"
        iconType="person"
        inputMode="numeric"
        labelText="Team size"
        onChange={(event) => {
          dispatchSearchState({ peopleMin: event.target.value });
          submitParams();
        }}
        pattern="[0-9]*"
        placeholder="e.g. 10"
        type="text"
        value={searchState.peopleMin}
      />
      <VerticalSpacing size="md" />

      {pricePlan ? (
        <OfficeDetail
          otherOptionsCount={otherOptionsCount}
          pricePlan={pricePlan}
        />
      ) : (
        <LinkAsButton
          href="#office-details"
          isBlock
          isCompact
          name="View offices"
        >
          View offices
        </LinkAsButton>
      )}
      <VerticalSpacing size="sm" />

      {(enableQuoteModals && (
        <ModalNew
          id="get-a-quote"
          label="Get a detailed quote"
          renderTrigger={({ openModal }) => (
            <Button
              isBlock
              isCompact
              name="Get a detailed quote"
              onClick={openModal}
              styleType="secondary"
              type="button"
            >
              Get a detailed quote
            </Button>
          )}
        >
          <QuoteModal
            buildingName={building.name}
            location={building.areas.at(-1).name}
            pricePlanId={pricePlan?.id}
            pricePlanSize={pricePlan?.capacity}
            userEmail={user?.email}
          />
        </ModalNew>
      )) || (
        <LinkAsButton
          href="https://search.hubblehq.com/get-a-quote"
          isBlock
          isCompact
          name="Get a detailed quote"
          styleType="secondary"
        >
          Get a detailed quote
        </LinkAsButton>
      )}

      <VerticalSpacing size="lg" />
      <HorizontalRule />
      <VerticalSpacing size="lg" />

      <AdvisorPromotionCard />
    </>
  );
}

OfficeFinder.propTypes = {
  building: BuildingPropTypes.isRequired,

  /**
   * Callback to dispatch changes to search state
   */
  dispatchSearchState: PropTypes.func.isRequired,

  matches: PropTypes.arrayOf(PricePlanPropTypes),

  /**
   * Current search parameters
   */
  searchState: PropTypes.shape({
    peopleMin: PropTypes.string,
  }),

  /**
   * Callback to persist search state to URL
   */
  submitParams: PropTypes.func.isRequired,
};
