import PropTypes from 'prop-types';

import CoworkingProductPropTypes from './CoworkingProduct.proptypes';
import MeetingRoomProductPropTypes from './MeetingRoomProduct.proptypes';
import PricePlanProptypes from './PricePlan.proptypes';
import PrivateOfficeProductPropTypes from './PrivateOfficeProduct.proptypes';

export default PropTypes.exact({
  address: PropTypes.string.isRequired,
  archivedAt: PropTypes.string,
  area: PropTypes.exact({
    id: PropTypes.number.isRequired,
    iso: PropTypes.string,
    name: PropTypes.string.isRequired,
    oldId: PropTypes.string,
    oldSlug: PropTypes.string.isRequired,
    parent: PropTypes.number,
    slug: PropTypes.string.isRequired,
  }),
  areas: PropTypes.arrayOf(
    PropTypes.exact({
      areaType: PropTypes.oneOf(['city', 'country']),
      id: PropTypes.number.isRequired,
      isAnchor: PropTypes.bool.isRequired,
      iso: PropTypes.string,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  billingEntityAddress: PropTypes.string,
  billingEntityConfirmedByHost: PropTypes.bool,
  billingEntityEmail: PropTypes.string,
  billingEntityId: PropTypes.number,
  billingEntityName: PropTypes.string,
  billingEntityPostcode: PropTypes.string,
  buildingType: PropTypes.exact({
    desc: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
    longDescription: PropTypes.string,
  }),
  city: PropTypes.exact({
    id: PropTypes.number.isRequired,
    iso: PropTypes.string,
    name: PropTypes.string.isRequired,
    oldId: PropTypes.string,
    oldSlug: PropTypes.string,
    parent: PropTypes.number,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  closed: PropTypes.array,
  costPerHour: PropTypes.number,
  country: PropTypes.exact({
    id: PropTypes.number.isRequired,
    iso: PropTypes.string,
    name: PropTypes.string,
    oldId: PropTypes.string,
    oldSlug: PropTypes.string,
    parent: PropTypes.number,
    slug: PropTypes.string,
  }),
  createdAt: PropTypes.string.isRequired,
  cultureDesc: PropTypes.string,
  deletedAt: PropTypes.string,
  facilities: PropTypes.arrayOf(PropTypes.string),
  freeTrialPassUseCap: PropTypes.number,
  getCurrency: PropTypes.string,
  highlights: PropTypes.arrayOf(
    PropTypes.exact({
      building: PropTypes.number,
      description: PropTypes.string.isRequired,
      id: PropTypes.number,
      index: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  hostId: PropTypes.number.isRequired,
  hostPaymentCurrency: PropTypes.string,
  howToFindUs: PropTypes.string,
  id: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.exact({
      caption: PropTypes.string,
      id: PropTypes.number,
      img: PropTypes.string,
      index: PropTypes.number,
      path: PropTypes.string,
      seatingConfig: PropTypes.string,
    }),
  ),
  isAvailableFullTime: PropTypes.bool.isRequired,
  isAvailableOnHq: PropTypes.bool.isRequired,
  isAvailableOnPass: PropTypes.bool.isRequired,
  isAvailablePartTime: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  isPromoted: PropTypes.bool.isRequired,
  isVrBookingSuspended: PropTypes.bool.isRequired,
  landmarks: PropTypes.arrayOf(
    PropTypes.exact({
      distance: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      landmark: PropTypes.exact({
        area: PropTypes.exact({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        images: PropTypes.arrayOf(
          PropTypes.exact({
            description: PropTypes.string,
            img: PropTypes.string.isRequired,
            index: PropTypes.number.isRequired,
          }),
        ),
        isSearchArea: PropTypes.bool,
        kind: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
        subType: PropTypes.string,
      }).isRequired,
    }),
  ),
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  location: PropTypes.exact({
    coordinates: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.string,
  }),
  minMonthsTerm: PropTypes.number,
  name: PropTypes.string,
  numberOfMeetingRooms: PropTypes.number,
  onDemandProduct: PropTypes.exact({
    accessHours: PropTypes.string,
    address: PropTypes.string,
    arrivalInstructions: PropTypes.string,
    building: PropTypes.number,
    buildingClosingTime: PropTypes.string,
    buildingName: PropTypes.string,
    buildingOpeningTime: PropTypes.string,
    contactEmailPass: PropTypes.string,
    contactEmailReception: PropTypes.string,
    createdAt: PropTypes.string,
    extraPassFacilities: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        caption: PropTypes.string,
        id: PropTypes.number,
        img: PropTypes.string,
        index: PropTypes.number,
        path: PropTypes.string,
        seatingConfig: PropTypes.string,
      }),
    ),
    includedPassFacilities: PropTypes.arrayOf(PropTypes.string),
    isPromoted: PropTypes.bool,
    onSiteRefreshments: PropTypes.string,
    products: PropTypes.arrayOf(CoworkingProductPropTypes),
    spaceForPrivatePhoneCalls: PropTypes.string,
    summary: PropTypes.string,
    updatedAt: PropTypes.string,
    wifiDetails: PropTypes.string,
  }),
  onDemandStatus: PropTypes.oneOf([
    'deleted',
    'archived',
    'draft',
    'hubble-review',
    'host-review',
    'not-set',
    'published',
  ]).isRequired,
  overview: PropTypes.string,
  parentArea: PropTypes.exact({
    id: PropTypes.number,
    iso: PropTypes.string,
    name: PropTypes.string,
    oldId: PropTypes.string,
    oldSlug: PropTypes.string,
    parent: PropTypes.number,
    slug: PropTypes.string,
  }),
  passCapacityLimit: PropTypes.number,
  passEmail: PropTypes.string,
  passPrice: PropTypes.number,
  postcode: PropTypes.string,
  pricePlans: PropTypes.arrayOf(PricePlanProptypes),
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([
      CoworkingProductPropTypes,
      MeetingRoomProductPropTypes,
      PrivateOfficeProductPropTypes,
    ]),
  ),
  publishedAt: PropTypes.string,
  receptionEmail: PropTypes.string,
  slug: PropTypes.string,
  specialOffer: PropTypes.string,
  status: PropTypes.oneOf([
    'deleted',
    'archived',
    'draft',
    'hubble-review',
    'host-review',
    'not-set',
    'published',
  ]).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.exact({
      index: PropTypes.number.isRequired,
      tag: PropTypes.string.isRequired,
    }),
  ).isRequired,
  timeZone: PropTypes.string,
  updatedAt: PropTypes.string,
  url: PropTypes.string,
  usesCreditSystem: PropTypes.bool,
  vatRegistered: PropTypes.bool,
  viewingsEndTime: PropTypes.string,
  viewingsStartTime: PropTypes.string,
  vrTourUrl: PropTypes.string,
});
