import { css } from '@emotion/css';
import React from 'react';

import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';
import theme from 'site-react/theme';

import LogoGrid from '../components/LogoGrid';

const contentWrapperStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 0 var(--space-md);
  }
`;

const headingStyle = css`
  align-self: start;
`;

const listStyle = css`
  list-style: none;
  padding: 0;
`;

const DefaultContent = () => {
  return (
    <aside className={contentWrapperStyle}>
      <Heading level="2" type="title3">
        We’re trusted by 2,500+ of the world’s most exciting companies
      </Heading>

      <VerticalSpacing size="lg" />

      <LogoGrid />

      <VerticalSpacing size="lg" />
      <HorizontalRule />
      <VerticalSpacing size="lg" />
      <div className={headingStyle}>
        <Heading level="3" type="title3">
          Hubble gives you the power to
        </Heading>
      </div>
      <VerticalSpacing size="sm" />

      <ul className={listStyle}>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Instantly search 5,000+ offices"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Give your team access to the world’s best workspaces, on-demand"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Understand, manage and share everything workplace related with your team"
        />
      </ul>
    </aside>
  );
};

export default DefaultContent;
