const getListingPageUrl = ({
  buildingId,
  buildingSlug = '',
  queryParams = {},
  isPass = false,
}) => {
  const relativeUrlPath = `${
    isPass ? '/pass' : ''
  }/office-space/${buildingId}/${buildingSlug}`;
  const params = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (typeof value !== 'undefined') {
      params.append(key, value);
    }
  });

  return relativeUrlPath + (params.toString().length > 0 ? `?${params}` : '');
};

export default getListingPageUrl;
