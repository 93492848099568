import PropTypes from 'prop-types';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { TextWithIcon } from 'site-react/components/typography';

import styles from './RecommendationListBuildModeBanner.module.css';

const RecommendationListBuildModeBanner = ({ recommendationList, onExit }) => (
  <div className={styles.RecommendationListBuildModeBanner}>
    <button
      className={styles['RecommendationListBuildModeBanner-exit']}
      data-testid="build-mode-banner-exit"
      onClick={onExit}
      type="button"
    >
      <b>
        <TextWithIcon
          contentType="content3"
          iconName="close"
          iconPosition="left"
          iconSize="lg"
          text="Exit build mode"
        />
      </b>
    </button>
    <span>
      <b>You are creating recommendations for {recommendationList.name}</b>
    </span>
    <LinkAsButton
      href=""
      isCompact
      name="RecommendationListItems"
      onClick={() => {}}
      styleType="secondary"
      type="button"
    >
      {recommendationList.items.length} office
      {recommendationList.items.length > 1 ||
      recommendationList.items.length === 0
        ? 's'
        : ''}
    </LinkAsButton>
  </div>
);

RecommendationListBuildModeBanner.propTypes = {
  onExit: PropTypes.func.isRequired,

  recommendationList: PropTypes.shape({
    advisor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    requesterEmail: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecommendationListBuildModeBanner;
