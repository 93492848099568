import { DateTime } from 'luxon';

import { VerticalSpacing } from 'site-react/components/utility';
import isHubbleUser from 'site-react/helpers/isHubbleUser';
import useUser from 'site-react/hooks/useUser';

import PrivateOfficeFullTimeCard from './components/PrivateOfficeFullTimeCard';
import PrivateOfficePartTimeCard from './components/PrivateOfficePartTimeCard';

export default function Office({
  buildingName,
  buildingType,
  isHighlighted,
  location,
  partTimeProductSettings,
  pricePlan,
  renderedMarkdownPricePlanContent,
  specialOffer,
}) {
  const keyFeaturesMissing = Boolean(
    !(
      pricePlan.keyFeatures ||
      pricePlan.notIncluded ||
      pricePlan.maxMonthsTerm
    ),
  );

  let availableFromText = 'Available now';
  let parsedAvailableFromDate = '';
  if (pricePlan.availableFrom) {
    parsedAvailableFromDate = DateTime.fromFormat(
      pricePlan.availableFrom,
      'yyyy-MM-dd',
    );
  }

  const isAvailableNow = !(
    pricePlan.availableFrom && parsedAvailableFromDate > DateTime.local()
  );
  if (!isAvailableNow) {
    availableFromText = `Available ${parsedAvailableFromDate.toFormat(
      'dd MMM yy',
    )}`;
  }

  const { user } = useUser();

  return (
    <li
      aria-label="Office Detail"
      data-testid={`office-detail-${pricePlan.pricePlanIdentifier}`}
      key={pricePlan.pricePlanIdentifier}
    >
      {pricePlan.typeOfPricePlan === 'part-time-office' ? (
        <>
          <PrivateOfficePartTimeCard
            accessHours={partTimeProductSettings?.accessHours}
            additionalCosts={partTimeProductSettings?.additionalCosts}
            availableFromText={availableFromText}
            buildingName={buildingName}
            canLeaveMonitor={partTimeProductSettings?.canLeaveMonitor}
            capacity={pricePlan.capacity}
            enableHoppyLink={isHubbleUser(user)}
            floor={pricePlan.floor}
            isHighlighted={isHighlighted}
            keyFeatures={pricePlan.keyFeatures}
            leavingEquipment={partTimeProductSettings?.leavingEquipment}
            location={location}
            maxTerm={partTimeProductSettings?.maxTerm}
            minTerm={partTimeProductSettings?.minTerm}
            notIncluded={pricePlan.notIncluded}
            partTimePriceOffPeak={pricePlan.partTimePriceOffPeak}
            partTimePricePeak={pricePlan.partTimePricePeak}
            pricePlanId={pricePlan.id}
            renderedMarkdownPricePlanContent={renderedMarkdownPricePlanContent}
            sqft={pricePlan.sqft}
            userEmail={user?.email}
          />
          <VerticalSpacing size="xl" />
        </>
      ) : (
        <>
          <PrivateOfficeFullTimeCard
            availableFromText={availableFromText}
            buildingName={buildingName}
            buildingType={buildingType}
            capacity={pricePlan.capacity}
            enableHoppyLink={isHubbleUser}
            floor={pricePlan.floor}
            isHighlighted={isHighlighted}
            keyFeatures={pricePlan.keyFeatures}
            keyFeaturesMissing={keyFeaturesMissing}
            location={location}
            maxMonthsTerm={pricePlan.maxMonthsTerm}
            minMonthsTerm={pricePlan.minMonthsTerm}
            notIncluded={pricePlan.notIncluded}
            price={pricePlan.price}
            pricePlanId={pricePlan.id}
            renderedMarkdownPricePlanContent={renderedMarkdownPricePlanContent}
            specialOffer={specialOffer}
            sqft={pricePlan.sqft}
            userEmail={user?.email}
          />
          <VerticalSpacing size="xl" />
        </>
      )}
    </li>
  );
}
