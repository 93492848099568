import { css, cx } from '@emotion/css';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import theme from 'site-react/theme';

const wrapperStyle = css`
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const wrapperStyleSelected = css`
  display: block;
`;

const imageFallbackStyle = (src) => css`
  /*
   * Some browsers (IE11) don't have object-fit support yet. Fall back to using
   * a background-image. Note: not supporting hi-res screens in this edge case.
   */
  background-image: url(${src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @supports (object-fit: cover) {
    /* Uses the normal img element */
    background-image: none;
  }
`;

const ImageCarouselItemCard = ({
  alt = '',
  loadable,
  prefetch = false,
  selected = false,
  src,
}) => {
  const srcUrl = new URL(src, getAppUrl(config));

  return (
    <li
      className={cx(wrapperStyle, {
        [imageFallbackStyle(srcUrl.pathname)]: prefetch || loadable,
        [wrapperStyleSelected]: selected,
      })}
    >
      <Image
        alt={alt}
        fill
        loader={imgixLoader}
        sizes={`
          /* At mobile listing cards fill the screen */
          (max-width: ${theme.breakpoints.md}px) 100vw,
          /* At desktop, listing cards will never be much wider than 392px */
          392px
          `}
        src={getImgixPathWithParameters(srcUrl.pathname, {
          ar: '4:3',
          fit: 'crop',
          q: 30,
        })}
        style={{
          objectFit: 'cover',
        }}
      />
    </li>
  );
};

ImageCarouselItemCard.propTypes = {
  /**
   * The alt text for this image
   */
  alt: PropTypes.string,

  loadable: PropTypes.bool,

  /**
   * Should we start loading this image, so it's ready when it gets displayed?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   * NOTE: this is actually used, but only in getDerivedStateFromProps. eslint
   * throws an inaccurate warning.
   */
  prefetch: PropTypes.bool,

  /**
   * Is this the currently-visible item?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   */
  selected: PropTypes.bool,

  /**
   * URL of the image to show. Expected to be a parameterless imgix URL.
   */
  src: PropTypes.string.isRequired,
};

export default ImageCarouselItemCard;
