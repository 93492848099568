import { css } from '@emotion/css';

import {
  citymapper,
  monzo,
  nhs,
  oddbox,
  pricewaterhouseCoopers,
  trustpilot,
} from 'site-react/assets';
import { Asset } from 'site-react/components/utility';
import theme from 'site-react/theme';

const logoList = css`
  display: grid;
  gap: var(--space-xxl) var(--space-xl);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  width: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;

  @media (min-width: ${theme.breakpoints.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const logoItem = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-height: 100%;
  }
`;

const logos = [
  {
    alt: 'Pricewaterhouse Coopers',
    src: pricewaterhouseCoopers,
  },
  { alt: 'Citymapper', src: citymapper },
  { alt: 'Monzo', src: monzo },
  { alt: 'Trustpilot', src: trustpilot },
  { alt: 'NHS', src: nhs },
  { alt: 'Oddbox', src: oddbox },
];

const LogoGrid = () => {
  return (
    <ul className={logoList}>
      {logos.map(({ alt, src }) => {
        return (
          <li className={logoItem} key={alt}>
            <Asset alt={alt} sizingBehaviour="fillToNatural" src={src} />
          </li>
        );
      })}
    </ul>
  );
};

export default LogoGrid;
