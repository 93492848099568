import { css } from '@emotion/css';
import React from 'react';

import {
  citymapper,
  monzo,
  nhs,
  oddbox,
  pricewaterhouseCoopers,
  trustpilot,
} from 'site-react/assets';
import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import {
  Asset,
  HorizontalRule,
  VerticalSpacing,
} from 'site-react/components/utility';
import theme from 'site-react/theme';

const contentWrapperStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 0 var(--space-md);
  }
`;

const headingStyle = css`
  align-self: start;
`;

const listStyle = css`
  list-style: none;
  padding: 0;
`;

const logosStyle = css`
  align-items: center;
  display: grid;
  gap: var(--space-base) var(--space-xl);
  grid-auto-rows: calc(var(--space-base) * 24); /* 96px */
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;

  @media (min-width: ${theme.breakpoints.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const MembershipAcceptLinkInviteContent = () => {
  const logos = [
    {
      alt: 'Pricewaterhouse Coopers',
      src: pricewaterhouseCoopers,
    },
    {
      alt: 'Citymapper',
      src: citymapper,
    },
    {
      alt: 'Monzo',
      src: monzo,
    },
    {
      alt: 'Trustpilot',
      src: trustpilot,
    },
    {
      alt: 'NHS',
      src: nhs,
    },
    {
      alt: 'Oddbox',
      src: oddbox,
    },
  ];

  return (
    <aside className={contentWrapperStyle}>
      <Heading level="2" type="title3">
        We’re trusted by 2,500+ of the world’s most exciting companies
      </Heading>

      <VerticalSpacing size="lg" />

      <ul className={logosStyle}>
        {logos.map(({ alt, src }) => {
          return (
            <li key={alt}>
              <Asset alt={alt} sizingBehaviour="fill" src={src} />
            </li>
          );
        })}
      </ul>

      <VerticalSpacing size="lg" />
      <HorizontalRule />
      <VerticalSpacing size="lg" />
      <div className={headingStyle}>
        <Heading level="3" type="title3">
          Hubble gives you the power to
        </Heading>
      </div>
      <VerticalSpacing size="sm" />

      <ul className={listStyle}>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Get access to the world's best workspaces, on-demand"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Make working from home feel GR8 with treats, virtual experiences and WFH essentials"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Understand, manage and share everything workplace related with your team"
        />
      </ul>
    </aside>
  );
};

export default MembershipAcceptLinkInviteContent;
