import { css, cx } from '@emotion/css';
import React from 'react';

import { AccountIcon } from 'site-react/components/utility';
import useUser from 'site-react/hooks/useUser';
import theme from 'site-react/theme';

const accountIconStyle = css`
  display: none;
  @media (min-width: ${theme.breakpoints.lg}px) {
    display: inline-block;
  }
`;

const accountLabelStyle = css`
  @media (min-width: ${theme.breakpoints.lg}px) {
    display: none;
  }
`;

const AccountItem = ({ className, children }) => {
  const { user } = useUser();

  return (
    <>
      <AccountIcon
        className={cx(className, accountIconStyle)}
        hover
        name={user?.name}
        size={38}
      />
      <span className={accountLabelStyle}>{children}</span>
    </>
  );
};

export default AccountItem;
