import PropTypes from 'prop-types';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getClosestLandmarkArea from 'site-react/helpers/getClosestLandmarkArea';

import styles from './ArchivedBanner.module.css';

const ArchivedBanner = ({ building }) => {
  const closestLandmark = getClosestLandmarkArea(building.landmarks);
  const urlSlug = closestLandmark ? closestLandmark.slug : '';

  return (
    <div className={styles.ArchivedBanner}>
      <VerticalSpacing size="xl" />
      <Heading isCentered level="2" type="title1">
        Sorry, there are no available offices in this building right now.
      </Heading>
      <VerticalSpacing size="sm" />
      <Paragraph isCentered type="content2">
        But don’t give up yet. We have more offices like this one that could be
        your perfect match.
      </Paragraph>
      <LinkAsButton
        analyticsMetadata={{
          location: 'Archived Banner',
        }}
        href={`/pass/london/${urlSlug}`}
        name="View Similar Offices"
        rel="nofollow noopener noreferrer"
        styleType="primary"
      >
        View similar offices
      </LinkAsButton>
    </div>
  );
};

ArchivedBanner.propTypes = {
  /**
   * Building object that includes landmarks
   */
  building: PropTypes.shape({
    landmarks: PropTypes.arrayOf(
      PropTypes.shape({
        distance: PropTypes.number.isRequired,
        landmark: PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
export default ArchivedBanner;
