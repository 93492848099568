import { css, cx } from '@emotion/css';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CustomDropdown } from 'site-react/components/form';
import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';
import analytics from 'site-react/helpers/Analytics';
import usePricePlan from 'site-react/hooks/usePricePlan';
import { PricePlanPropTypes } from 'site-react/proptypes';
import theme from 'site-react/theme';

import DisplayComponent from './components/DisplayComponent/DisplayComponent';

const hideInMobileStyle = css`
  display: none;

  @media (min-width: ${theme.breakpoints.md}px) {
    display: block;
  }
`;

const wrapperStyle = css`
  flex: 1;
`;

const getFullTimeOfficeTitleContent = ({ price }) =>
  `Private Office - £${price.toLocaleString()} / mo (excl VAT)`;

const onPricePlanDropdownClicked = (selectedPricePlan) => {
  const { buildingDetail } = selectedPricePlan || {};
  analytics.track('Office Selection Dropdown Clicked', {
    building: buildingDetail,
    button_location: 'sidebar',
    dropdown_opened: true,
  });
};

const DropdownPricePlan = ({
  isDisabled = false,
  isHiddenOnMobile = false,
  pricePlans,
  selectedPricePlan = null,
  testId = null,
}) => {
  const { push, query } = useRouter();
  const { setPricePlan } = usePricePlan();
  const { officeAccess } = useContext(ViewingRequestContext);

  const getOfficeSelectorContent = (pricePlan) => {
    if (officeAccess === OfficeAccessOptions.PartTime) {
      return ` Part-time - from £${Math.ceil(
        pricePlan?.partTimePriceOffPeak * 2,
      )} / mo (excl VAT)
    `;
    } else return getFullTimeOfficeTitleContent(pricePlan);
  };

  const partTimeOfficePricePlans = pricePlans.filter(
    (pricePlan) => pricePlan.typeOfPricePlan === 'part-time-office',
  );

  const fullTimeOfficePricePlans = pricePlans.filter(
    (pricePlan) => pricePlan.typeOfPricePlan === 'full-time-office',
  );

  const officePricePlans =
    officeAccess === OfficeAccessOptions.PartTime
      ? partTimeOfficePricePlans
      : fullTimeOfficePricePlans;

  // In the event that the office access is set to part time, we want to ensure that the
  // selected price plan passed as a prop is not a PTO that does not meet
  // the minimum desk requirement (i.e. the cheapest price plan)
  const getValidPricePlanToDisplay = () => {
    const pricePlanToDisplay = officePricePlans.find((pricePlan) => {
      return pricePlan.id === selectedPricePlan?.id;
    });
    return pricePlanToDisplay ?? partTimeOfficePricePlans[0];
  };

  return (
    <div
      className={cx(wrapperStyle, { [hideInMobileStyle]: isHiddenOnMobile })}
    >
      <CustomDropdown
        disabled={isDisabled}
        displayComponent={
          <DisplayComponent selectedPricePlan={getValidPricePlanToDisplay()} />
        }
        onChange={(event) => {
          const formattedPricePlanId = parseInt(event.target.value, 10);
          const defaultPricePlanId = pricePlans[0].id;

          const newQuery = { ...query };

          if (formattedPricePlanId === defaultPricePlanId) {
            delete newQuery.option;
          } else {
            newQuery.option = formattedPricePlanId;
          }

          setPricePlan(formattedPricePlanId);
          push(
            {
              query: newQuery,
            },

            // Setting this to null means Next will use a default value.
            null,

            // Shallow routing prevents doing a round trip to the server. Without
            // this, changing the dropdown is super duper slow.
            { shallow: true },
          );
        }}
        onClick={() => {
          onPricePlanDropdownClicked(selectedPricePlan);
        }}
        testId={testId}
        value={String(selectedPricePlan ? selectedPricePlan.id : '')}
      >
        {officePricePlans.length ? (
          officePricePlans.map((pricePlan) => (
            <option key={pricePlan.pricePlanIdentifier} value={pricePlan.id}>
              {getOfficeSelectorContent(pricePlan)} - Desks Available:{' '}
              {pricePlan.desksAvailable}
            </option>
          ))
        ) : (
          <option disabled>No plans available</option>
        )}
      </CustomDropdown>
    </div>
  );
};

DropdownPricePlan.propTypes = {
  /**
   * The 'disabled' state attribute for the select.
   */
  isDisabled: PropTypes.bool,

  /**
   * Hides this element in mobile for space reasons.
   */
  isHiddenOnMobile: PropTypes.bool,

  /**
   * A list of available price plans.
   */
  pricePlans: PropTypes.arrayOf(PricePlanPropTypes).isRequired,

  /**
   * The price plan that's currently selected
   */
  selectedPricePlan: PricePlanPropTypes,

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,
};

export default DropdownPricePlan;
