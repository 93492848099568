// Illustrations
export { default as backgroundGradient } from './illustrations/background-gradient.svg';
export { default as balancedWorkEnvironment } from './illustrations/balancedWorkEnvironment.svg';
export { default as blobGradient } from './illustrations/blob-gradient.svg';
export { default as buildingPass } from './illustrations/buildingPass.svg';
export { default as cafeWorking } from './illustrations/cafe-working.svg';
export { default as calculatorIcon } from './illustrations/calculator-icon.svg';
export { default as chair } from './illustrations/chair.svg';
export { default as coworkingBooked } from './illustrations/co-working-booked.svg';
export { default as companyOffice } from './illustrations/company-office.svg';
export { default as coworkingProduct } from './illustrations/coworking-product.svg';
export { default as createAccountSurvey } from './illustrations/createAccountSurvey.svg';
export { default as emailSent } from './illustrations/email-sent.svg';
export { default as eventSpace } from './illustrations/event-space.svg';
export { default as fullyRemote } from './illustrations/fully-remote.svg';
export { default as heidiSurvey } from './illustrations/heidiSurvey.svg';
export { default as hotDesk } from './illustrations/hot-desk.svg';
export { default as hotFixedDeskProduct } from './illustrations/hot-fixed-desk-product.svg';
export { default as hqIcon } from './illustrations/hq-icon.svg';
export { default as hqOfficeProduct } from './illustrations/hq-office-product.svg';
export { default as hubblehqMan } from './illustrations/hubblehqMan.svg';
export { default as hubbleSlack } from './illustrations/hubbleSlack.svg';
export { default as hybrid } from './illustrations/hybrid.svg';
export { default as illustrationDemographics } from './illustrations/illustration-01-demographics.svg';
export { default as lostPerson } from './illustrations/lost-person.svg';
export { default as meetingRoom } from './illustrations/meeting-room.svg';
export { default as newWaysOfWork } from './illustrations/new-ways-of-work.svg';
export { default as officeFirst } from './illustrations/office-first.svg';
export { default as officeBuilding } from './illustrations/officeBuilding.svg';
export { default as officeChair } from './illustrations/officeChair.svg';
export { default as openDoor } from './illustrations/open-door.svg';
export { default as pairOfUsers } from './illustrations/pair-of-users.svg';
export { default as paperAirplane } from './illustrations/paper-airplane.svg';
export { default as passIcon } from './illustrations/pass-icon.svg';
export { default as phoneBox } from './illustrations/phoneBox.svg';
export { default as pinkBlobSavings } from './illustrations/pink-blob-savings-seventy-five-percent.svg';
export { default as privateDayOfficeProduct } from './illustrations/private-day-office-product.svg';
export { default as privateOffice } from './illustrations/private-office.svg';
export { default as resultsGaugeHybridFooter } from './illustrations/results-gauge-hybrid-footer.svg';
export { default as resultsGaugeHybrid } from './illustrations/results-gauge-hybrid.svg';
export { default as resultsSurvey } from './illustrations/resultsSurvey.svg';
export { default as rulerTick } from './illustrations/rulerTick.svg';
export { default as scientist } from './illustrations/scientist.svg';
export { default as wfh } from './illustrations/wfh.svg';

// Logos
export { default as britishLand } from './logos/british-land.svg';
export { default as chillys } from './logos/chillys.svg';
export { default as citymapper } from './logos/citymapper.svg';
export { default as citymapperColor } from './logos/citymapper-color.svg';
export { default as citymapperColorOneline } from './logos/citymapperColorOneline.svg';
export { default as citymapperWhite } from './logos/citymapper-white.svg';
export { default as facebook } from './logos/facebook.svg';
export { default as fora } from './logos/fora.svg';
export { default as freenow } from './logos/freenow.svg';
export { default as google } from './logos/google.svg';
export { default as labs } from './logos/labs.svg';
export { default as landmark } from './logos/landmark.svg';
export { default as mindspace } from './logos/mindspace.svg';
export { default as monzo } from './logos/monzo.svg';
export { default as monzoColor } from './logos/monzo-color.svg';
export { default as monzoWhite } from './logos/monzo-white.svg';
export { default as netflix } from './logos/netflix.svg';
export { default as nhs } from './logos/nhs.svg';
export { default as oddbox } from './logos/oddbox.svg';
export { default as olio } from './logos/olio-logo.svg';
export { default as patch } from './logos/patch.svg';
export { default as pricewaterhouseCoopers } from './logos/pricewaterhouse-coopers.svg';
export { default as secondHome } from './logos/second-home.svg';
export { default as shutterstock } from './logos/shutterstock.svg';
export { default as smol } from './logos/smol.svg';
export { default as specsavers } from './logos/specsavers.svg';
export { default as starlingColor } from './logos/starling-color.svg';
export { default as taskrabbit } from './logos/taskrabbit-logo.svg';
export { default as theCrownEstate } from './logos/the-crown-estate.svg';
export { default as thriva } from './logos/thriva.svg';
export { default as tog } from './logos/tog.svg';
export { default as togBlue } from './logos/tog-blue.svg';
export { default as trustpilot } from './logos/trustpilot.svg';
export { default as trustpilotRating } from './logos/trustpilot-rating.svg';
export { default as trustpilotWhite } from './logos/trustPilot-white.svg';
export { default as ucas } from './logos/ucas.svg';
export { default as uncommon } from './logos/uncommon.svg';
export { default as wework } from './logos/wework.svg';
export { default as workersLeague } from './logos/workersleague.svg';
export { default as workersLeagueBadge } from './logos/workersleague-badge.svg';
export { default as workersLeagueWhite } from './logos/workersleague-white.svg';
export { default as worklife } from './logos/worklife.svg';

// Sparkles
export { default as blob1 } from './sparkles/blob1.svg';
export { default as blob2 } from './sparkles/blob2.svg';
export { default as blob3 } from './sparkles/blob3.svg';
export { default as blackPlus } from './sparkles/blackPlus.svg';
export { default as blueStar } from './sparkles/blueStar.svg';
export { default as blueBubbles } from './sparkles/blueBubbles.svg';
export { default as flourishBolt } from './sparkles/flourishBolt.svg';
export { default as flourishCubes } from './sparkles/flourishCubes.svg';
export { default as flourishOrbs } from './sparkles/flourishOrbs.svg';
export { default as flourishPinkOrbs } from './sparkles/flourishPinkOrbs.svg';
export { default as flourishSquiggle } from './sparkles/flourishSquiggle.svg';
export { default as flourishStart } from './sparkles/flourishStars.svg';
export { default as flourishStarsBlackGreen } from './sparkles/flourishStarsBlackGreen.svg';
export { default as flourishTwinkles } from './sparkles/flourishTwinkles.svg';
export { default as oStar } from './sparkles/o-star.svg';
export { default as pinkSpark } from './sparkles/pinkSpark.svg';
export { default as purpleGreen } from './sparkles/purpleGreen.svg';
export { default as redArrow } from './sparkles/redArrow.svg';
