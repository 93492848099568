import React, { createContext, useEffect, useMemo } from 'react';

import config from 'site-react/config';

import useCookieManagePreferences from './hooks/useManageCookiePreferences';
import useSegmentDestinations from './hooks/useSegementDestinations';
import useTagManager from './hooks/useTagManager';
import loadLegacyAnalytics from './loadLegacyAnalytics';
import preferenceOptions from './preferenceOptions';

const CookiePreferencesContext = createContext();
function CookiePreferencesProvider(props) {
  const destinations = useSegmentDestinations(
    config.CUSTOMER_EVENTS_SEGMENT_TOKEN,
  );

  const {
    preferences,
    setPreferences,
    acceptAll,
    declineAll,
    hasSetPreferences,
  } = useCookieManagePreferences(preferenceOptions);

  const { isReady: isGtmLoadable } = useTagManager(
    hasSetPreferences,
    preferences,
  );

  useEffect(() => {
    if (hasSetPreferences) {
      loadLegacyAnalytics(destinations, preferences);
    }
  }, [hasSetPreferences, destinations, preferences]);

  const value = useMemo(
    () => ({
      acceptAll,
      availablePreferences: preferenceOptions,
      declineAll,
      hasSetPreferences,
      isGtmLoadable,
      preferences,
      setPreferences,
    }),
    [
      acceptAll,
      declineAll,
      isGtmLoadable,
      preferences,
      setPreferences,
      hasSetPreferences,
    ],
  );

  return <CookiePreferencesContext.Provider value={value} {...props} />;
}

export { CookiePreferencesContext };
export default CookiePreferencesProvider;
