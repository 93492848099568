import { css } from '@emotion/css';
import { keyframes } from '@emotion/react';
import React, { useEffect, useState, Suspense } from 'react';

import { Button } from 'site-react/components/form';
import { Modal } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Spinner, VerticalSpacing } from 'site-react/components/utility';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';
import theme from 'site-react/theme';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const containerStyle = css`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: ${theme.zIndex.cookieBanner};
  background-color: rgb(var(--color-white-rgb) / 80%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s ease;
`;

const bannerStyle = css`
  color: var(--color-white);
  text-decoration: none;
  padding: var(--space-md);

  @media (min-width: ${theme.breakpoints.md}px) {
    width: 544px;
    height: 436px;
  }
`;

const wrapperStyle = css`
  background-color: var(--color-neutral-900);
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-lg) var(--space-md);
`;

const highlightedTextStyle = css`
  font: var(--font-style-title-1);
  @media (min-width: ${theme.breakpoints.md}px) {
    max-width: 90%;
    font: var(--font-style-hero-2);
  }
`;

const actionsWrapperStyle = css`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const buttonWrapperStyle = css`
  margin-left: var(--space-md);
`;

const linkStyle = css`
  font: var(--font-style-content-3);
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  flex-shrink: 0;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

const modalWrapperStyle = css`
  padding: var(--space-xxxl) var(--space-xxl) var(--space-xxl);
  background-color: var(--color-neutral-900);
  color: var(--color-white);
`;

const CookieBanner = () => {
  const { acceptAll } = useCookiePreferences();
  const [widthOfViewport] = useState(window.innerWidth);
  const isMobile = widthOfViewport < theme.breakpoints.md;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'scroll');
  });

  const CookieManagePreferencesForm = React.lazy(
    () => import('site-react/features/CookieManagePreferences'),
  );

  return (
    <div className={containerStyle}>
      <div className={bannerStyle}>
        <div className={wrapperStyle}>
          <div className={highlightedTextStyle}>
            <Heading type="hero2">We’d like to give you some cookies</Heading>
            <VerticalSpacing size="sm" />
            <Paragraph type="content2">
              Not the biscuity kind, many of our workspaces give you those for
              free. The ones we have are used to enhance your experience and for
              marketing and analytics purposes. You can learn more in our{' '}
              <a href="/privacy-policy" target="_blank">
                Privacy & Cookies Policy
              </a>
              .
            </Paragraph>
          </div>
          <VerticalSpacing size="xxl" />
          <div className={actionsWrapperStyle}>
            <Modal
              id="cookie-manage-preferences"
              modalName="Cookie Settings"
              name="Settings"
              renderTrigger={({ openModal }) => (
                <button className={linkStyle} onClick={openModal} type="button">
                  Cookie Settings
                </button>
              )}
              unsetBackground={true}
            >
              {({ closeModal }) => (
                <div className={modalWrapperStyle}>
                  <Suspense fallback={<Spinner />}>
                    <CookieManagePreferencesForm close={closeModal} />
                  </Suspense>
                </div>
              )}
            </Modal>
            <div className={buttonWrapperStyle}>
              <Button name="Accept Cookies" onClick={acceptAll} type="button">
                {isMobile ? 'Accept' : 'Accept cookies'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
