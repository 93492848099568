import { css } from '@emotion/css';
import React from 'react';

import { BuildingPropTypes } from 'site-react/proptypes';
import theme from 'site-react/theme';

import ArchivedBanner from './ArchivedBanner';
import BookThisSpace from '../BookThisSpace';
import PassPromotionCard from '../PassPromotionCard/PassPromotionCard';

const navbarHeight = theme.spacing.base * 21; /* 84px */
const primaryNavBarHeight = theme.spacing.base * 17; /* 68px */

const wrapperStyle = css`
  /**
   * 1. N.B. CTAs are shown at the bottom of the page; this margin ensures
   *    space between those CTAs and the Similar Workspaces heading
   * 2. Align to the right
   * 3. Normally, with overflow: hidden;, box-shadows get clipped off. This hack
   *    works by padding the overflowing wrapper enough that the shadow can
   *    draw completely _inside_ the wrapper, then uses negative margins to
   *    expand the wrapper back out and align with the underlying grid
   * 4. The wrapper will stick just below the header and page nav. We want it to
   *    occupy the whole remaining height, worked out by subtracting the nav
   *    heights from 100vh
   * 5. Prevent the panel from looking too stretched (sausage-button-syndrome)
   * 6. Allow for vertical scrolling on shorter screens
   */
  margin-bottom: var(--space-xxl); /* 1 */

  @media (min-width: ${theme.breakpoints.lg}px) {
    float: right; /* 2 */
    margin: calc(var(--space-md) * -1); /* 3 */
    max-height: calc(100vh - ${navbarHeight + primaryNavBarHeight}px); /* 4 */
    max-width: calc(var(--space-base) * 112); /* =448px, 5 */
    overflow-x: hidden; /* 6 */
    overflow-y: auto; /* 6 */
    padding: var(--space-md); /* 3 */
    padding-bottom: var(--space-xl);
    position: sticky;
    top: ${navbarHeight + primaryNavBarHeight}px; /* 4 */
    width: 100%;
  }
`;

const shadowPanel = `0 -1px 16px 0 rgb(var(--color-neutral-900-rgb) / 10%)`;

const panelStyle = css`
  align-items: center;
  background-color: var(--color-white);
  bottom: 0;
  box-shadow: ${shadowPanel};
  display: flex;
  left: 0;
  min-height: calc(var(--space-base) * 16);
  padding-bottom: env(
    safe-area-inset-bottom,
    0px
  ); /* Add padding, to avoid intersection with iOS/Android home indicators */
  position: fixed;
  right: 0;
  z-index: ${theme.zIndex.drawer};

  @media (min-width: ${theme.breakpoints.lg}px) {
    border-radius: var(--space-md);
    bottom: auto;
    box-shadow: ${theme.shadow.resting};
    display: block;
    margin-bottom: var(--space-lg);
    padding: var(--space-lg);
    position: static;
  }
`;

const PassActions = ({ building }) => {
  return (
    <div className={wrapperStyle}>
      <div className={panelStyle}>
        {building.onDemandStatus === 'archived' ? (
          <ArchivedBanner building={building} />
        ) : (
          <BookThisSpace building={building} city={building.city} />
        )}
      </div>
      <PassPromotionCard />
    </div>
  );
};

PassActions.propTypes = {
  building: BuildingPropTypes.isRequired,
};

export default PassActions;
