import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid, Modal } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import Map from 'site-react/features/Map';
import analytics from 'site-react/helpers/Analytics';
import theme from 'site-react/theme';

const gridRowGapSize = 72;

const expandedMapContainerStyle = css`
  height: calc(100vh - ${gridRowGapSize}px);
`;

// Derived from 287 / 160, the map dimensions in the mobile designs.
const mobileAspectRatio = 287 / 160;

const imageStyle = css`
  display: block;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}px) {
    max-width: 100%;
    width: auto;
  }
`;

const pictureStyle = css`
  display: block;
`;

const wrapperStyle = css`
  border: none;
  border-radius: calc(var(--space-base) * 2);
  box-shadow: ${theme.shadow.resting};
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0;
  transition: ${theme.animation.timings.short} linear box-shadow;

  :hover,
  :focus {
    box-shadow: ${theme.shadow.raised};
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    width: auto;
  }
`;

const headerMargin = theme.spacing.base * 3;

const headingStyle = css`
  // create space for the close button in mobile view
  margin: ${headerMargin}px calc(var(--space-base) * 14) ${headerMargin}px
    ${headerMargin}px;

  @media (min-width: ${theme.breakpoints.md}px) {
    margin: ${headerMargin}px;
  }
`;

const MiniMap = ({
  areaName,
  buildingName,
  fullWidth = false,
  lat,
  lng,
  trackingId,
}) => {
  const pinColor = theme.colors.brandSecondary.replace('#', '');
  const getMapImageUrl = ({ width, height, lat, lng, scale }) =>
    `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=13&size=${width}x${height}&scale=${scale}&maptype=roadmap&markers=color:0x${pinColor}%7C${lat},${lng}&key=AIzaSyB0rSQElEVGE5bDk0-8NjH93MhHpEnOimU`;

  const getMapImageSrcSet = (params) =>
    [1, 2]
      .map((scale) => `${getMapImageUrl({ ...params, scale })} ${scale}x`)
      .join(', ');

  const getImageDimensions = (fullWidth) => [
    {
      height: 160,
      media: `(min-width: ${theme.breakpoints.md}px)`,
      // Desktop - anything above md
      width: fullWidth ? 640 : 178,
    },
    {
      // Mobile, sm -> md
      height: Math.floor(640 / mobileAspectRatio),
      media: `(min-width: ${theme.breakpoints.sm}px)`,
      // Google Maps is unwilling to serve an image wider than 640
      width: 640,
    },
    {
      height: Math.floor(theme.breakpoints.sm / mobileAspectRatio),
      // Mobile, xs
      width: theme.breakpoints.sm,
    },
  ];

  const coordinates = {
    lat,
    lng,
  };

  const results = [
    {
      buildingLocation: {
        coordinates: [lng, lat],
      },
      id: 1,
    },
  ];

  return (
    <Modal
      id="mini-map-modal"
      isFullScreen
      modalName="Mini Map"
      renderTrigger={({ openModal }) => (
        <button
          className={wrapperStyle}
          onClick={() => {
            openModal();
            analytics.track('Map Clicked', {
              content_ids: [trackingId],
              location_lat: lat,
              location_long: lng,
            });
          }}
          type="button"
        >
          <picture className={pictureStyle}>
            {getImageDimensions(fullWidth).map(({ width, height, media }) => {
              const mapParams = { height, width, ...coordinates };

              return media ? (
                <source
                  className={imageStyle}
                  key={media}
                  media={media}
                  srcSet={getMapImageSrcSet(mapParams)}
                />
              ) : (
                <img
                  alt={`Map of the ${areaName} area`}
                  className={imageStyle}
                  key="default"
                  src={getMapImageUrl(mapParams)}
                  srcSet={getMapImageSrcSet(mapParams)}
                />
              );
            })}
          </picture>
        </button>
      )}
    >
      <Grid>
        <Grid.Item align="center" colSpan="12" justify="center" rowSpan="3">
          <div className={headingStyle}>
            <Heading level="2" type="title1">
              {buildingName}
            </Heading>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="12">
          <div className={expandedMapContainerStyle}>
            <Map results={results} />
          </div>
        </Grid.Item>
      </Grid>
    </Modal>
  );
};

MiniMap.propTypes = {
  /**
   * The name of the area. Used for accessibility and alt props.
   */
  areaName: PropTypes.string.isRequired,

  /**
   * The name of the building to be represented in the Map.
   */
  buildingName: PropTypes.string.isRequired,

  /**
   * Should this map always display full width?
   *
   * This would normally be true if there were no nearby landmarks to show. It
   * allows the map to fill in the whitespace.
   */
  fullWidth: PropTypes.bool,

  /**
   * Latitude of the pin
   */
  lat: PropTypes.number.isRequired,

  /**
   * Longitude of the pin
   */
  lng: PropTypes.number.isRequired,

  /**
   * The ID that should be associated with this map. Used for analytics.
   *
   * Suggested value: the current listing or office space ID.
   */
  trackingId: PropTypes.number.isRequired,
};

export default MiniMap;
